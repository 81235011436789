import React, { useState, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { SearchNormal1 } from 'iconsax-react';
import { apiHost } from '../../constants';
import Header from '../../layout/header';
import AdvanceSearch, { initialState } from '../../components/AdvanceSearch';
import Pagination from '../../components/Pagination';
import jobService from '../../services/job-service';
import BrowseJobCard from '../../components/BrowseJobCard';
import { Link } from 'react-router-dom';

export default function BrowseJobs(props) {
  const [user, setUser] = useState('');
  const [jobs, setJobs] = useState([]);
  const [filterData, setFilterData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [userLocation, setUserLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      setUser(parsedUser);
    }
  }, []);

  useEffect(() => {
    const getUserLocation = () => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            fetchJobs(position.coords.latitude, position.coords.longitude);
          },
          (error) => {
            setLocationError(
              'Location access denied. Please enable location services.'
            );
            setLoading(false);
          }
        );
      } else {
        setLocationError('Geolocation is not supported by this browser.');
        setLoading(false);
      }
    };

    const fetchJobs = async (latitude, longitude) => {
      try {
        const queryParams = `latitude=${latitude}&longitude=${longitude}`;
        const response = await jobService.searchJobs(queryParams);

        if (response?.error) {
          if (!response?.error.includes('not found'))
            toast.error(response?.error);
        } else {
          setJobs(response);
        }
      } catch (error) {
        toast.error('Failed to fetch jobs');
      } finally {
        setLoading(false);
      }
    };

    getUserLocation();
  }, []);

  const calculateDistance = (location1, location2) => {
    const R = 6371;
    const dLat = (location2.lat - location1.lat) * (Math.PI / 180);
    const dLon = (location2.lon - location1.lon) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(location1.lat * (Math.PI / 180)) *
        Math.cos(location2.lat * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const { filteredJobs } = useMemo(() => {
    const filterJobs = (jobs) => {
      return jobs
        .filter((job) => {
          let result = true;

          if (filterData.keywords) {
            result =
              result &&
              (job.title
                .toLowerCase()
                .includes(filterData.keywords.toLowerCase()) ||
                job.description
                  .toLowerCase()
                  .includes(filterData.keywords.toLowerCase()));
          }

          if (userLocation && filterData.radius) {
            const jobLocation = { lat: job.latitude, lon: job.longitude };
            const distance = calculateDistance(userLocation, jobLocation);
            result = result && distance <= filterData.radius;
          }

          if (filterData.minPrice !== '' && filterData.maxPrice !== '') {
            result =
              result &&
              job.price >= filterData.minPrice &&
              job.price <= filterData.maxPrice;
          }
          if (filterData.minPrice !== '') {
            result = result && job.price >= filterData.minPrice;
          }
          if (filterData.maxPrice !== '') {
            result = result && job.price <= filterData.maxPrice;
          }

          if (filterData.difficultyLevel.length > 0) {
            result =
              result &&
              filterData.difficultyLevel.includes(job.difficultyLevel);
          }

          return result;
        })
        .filter((job) => {
          if (searchText) {
            return (
              job.title.toLowerCase().includes(searchText.toLowerCase()) ||
              job.description.toLowerCase().includes(searchText.toLowerCase())
            );
          }
          return true;
        });
    };

    return { filteredJobs: filterJobs(jobs) };
  }, [jobs, searchText, filterData, userLocation]);

  const renderJobItems = (jobs) => {
    if (loading) {
      return <div className='text-info'>Loading jobs...</div>;
    }

    if (!loading && jobs.length === 0) {
      return <div className='alert alert-warning'>No jobs to display</div>;
    }

    return (
      <div className='row'>
        {jobs.map((job) => (
          <BrowseJobCard
            key={job.id}
            jobTitle={job.title}
            href={`/dashboard/job-details/${job.id}`}
            image={`${apiHost}/${job.JobImages[0]?.url}`}
            difficultyLevel={job.difficultyLevel}
            radius={job.radius}
            userName={`${job.Recruiter?.firstName} ${job.Recruiter?.lastName}`}
            price={job.price}
            rating={job.Recruiter?.rating}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <Header Display='none' href='' headerTitle='Browse Jobs' />
      <div className='container-fluid px-0'>
        <div className='row mb-48'>
          <div className='col-12 col-md-6 col-lg-8 col-xl-6  dashboard-search'>
            <div className='search-input d-flex align-items-center '>
              <input
                type='text'
                onChange={(e) => setSearchText(e.target.value)}
                className='form-control '
                placeholder='Search something...'
              />
              <div className='d-flex align-items-center advance-search gap-3  flex-grow-1 '>
                <p
                  className='violet mb-0 pe-3 cursor-pointer'
                  data-bs-toggle='modal'
                  data-bs-target='#advanceSearch'
                >
                  Advanced Search
                </p>
                <SearchNormal1 size='20' color='#020617' />
              </div>
            </div>
          </div>
          {!user && (
            <div className='col-12 col-md-6 add-productbtn-col text-end'>
              <Link to='/' className='btn'>
                Add a Job
              </Link>
            </div>
          )}
        </div>
      </div>
      {locationError ? (
        <div className='alert alert-danger'>{locationError}</div>
      ) : (
        <div className='container-fluid px-0'>
          <Pagination
            isTable={false}
            renderComponent={renderJobItems}
            items={filteredJobs}
          />
        </div>
      )}

      <AdvanceSearch
        onReset={() => setFilterData(initialState)}
        onSaveClick={setFilterData}
      />
    </>
  );
}
