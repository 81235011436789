import React, { useState } from 'react';

export const initialState = {
  keywords: '',
  radius: '',
  minPrice: '',
  maxPrice: '',
  difficultyLevel: [],
};

export default function AdvanceSearch({ onSaveClick, onReset }) {
  const [difficultyLevel, setDifficultyLevel] = useState([
    { difficultyName: 'Easy', color: false },
    { difficultyName: 'Medium', color: false },
    { difficultyName: 'Hard', color: false },
  ]);
  const [filterData, setFilterData] = useState(initialState);

  const changeActive = (index) => {
    const data = [...difficultyLevel];
    data[index].color = !data[index].color;
    const filteredData = data.filter((d) => d.color === true);
    setFilterData((prev) => ({
      ...prev,
      difficultyLevel: filteredData.map((f) => f.difficultyName),
    }));
    setDifficultyLevel(data);
  };

  return (
    <>
      <div className='modal' id='advanceSearch'>
        <div className='modal-dialog modal-dialog-centered me-0 mt-0 mb-0'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Advanced Search</h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body advance-search-modal position-relative p-0 text-center'>
              <div className='auth-form d-flex flex-column gap-32 p-32 '>
                <div className='gap-4 d-flex flex-column align-items-start'>
                  <label className=''>Keywords</label>
                  <input
                    type='text'
                    name='keywords'
                    className='form-control'
                    placeholder='Enter keywords'
                    value={filterData.keywords}
                    onChange={(e) =>
                      setFilterData((prev) => ({
                        ...prev,
                        keywords: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className='gap-4 d-flex flex-column align-items-start'>
                  <label className=''>Radius (Mi)</label>
                  <input
                    type='number'
                    name='radius'
                    className='form-control'
                    placeholder='Enter radius in miles'
                    value={filterData.radius ? Number(filterData.radius) : ''}
                    onChange={(e) =>
                      setFilterData((prev) => ({
                        ...prev,
                        radius: Number(e.target.value),
                      }))
                    }
                  />
                </div>

                <div className='gap-4 d-flex flex-column align-items-start'>
                  <label className=''>Price Range</label>
                  <div className='d-flex align-items-center w-100 gap-3'>
                    <input
                      type='number'
                      name='minPrice'
                      className='form-control flex-grow-1 '
                      placeholder='Minimum Price'
                      value={
                        filterData.minPrice ? Number(filterData.minPrice) : ''
                      }
                      onChange={(e) =>
                        setFilterData((prev) => ({
                          ...prev,
                          minPrice: Number(e.target.value),
                        }))
                      }
                    />
                    <input
                      type='number'
                      name='maxPrice'
                      className='form-control'
                      placeholder='Maximum Price'
                      value={
                        filterData.maxPrice ? Number(filterData.maxPrice) : ''
                      }
                      onChange={(e) =>
                        setFilterData((prev) => ({
                          ...prev,
                          maxPrice: Number(e.target.value),
                        }))
                      }
                    />
                  </div>
                </div>

                <div className='text-start d-flex flex-column gap-4  category-list-col'>
                  <label>Difficulty Levels</label>

                  <ul className='d-flex align-items-center flex-wrap  gap-4'>
                    {difficultyLevel.map((items, index) => (
                      <li key={index}>
                        <button
                          className='d-flex align-items-center justify-content-center'
                          onClick={() => changeActive(index)}
                          style={{
                            background:
                              items.color === true ? '#E3F6FC' : '#fff',
                            color: items.color === true ? '#3ab1ed' : '#020617',
                            border:
                              items.color === true
                                ? '1px solid transparent'
                                : '1px solid #E2E8F0',
                          }}
                        >
                          {items.difficultyName}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center modal-footer p-32 justify-content-between '>
              <button
                type='button'
                className='btn button2'
                data-bs-dismiss='modal'
                onClick={() => {
                  onReset();
                  filterData.quantity = 0;
                  setDifficultyLevel((prev) =>
                    prev.map((p) => ({
                      difficultyName: p.difficultyName,
                      color: false,
                    }))
                  );
                  setFilterData(initialState);
                }}
              >
                Clear All
              </button>

              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => onSaveClick(filterData)}
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
