import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const LocationWarning = () => {
  const [locationEnabled, setLocationEnabled] = useState(true);

  useEffect(() => {
    const checkLocationServices = () => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          () => {
            setLocationEnabled(true);
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              setLocationEnabled(false);
            }
          }
        );
      } else {
        setLocationEnabled(false);
        toast.error('Geolocation is not supported by this browser.');
      }
    };

    checkLocationServices();
  }, []);

  return (
    <>
      {!locationEnabled && (
        <div
          style={{
            padding: '10px',
            backgroundColor: '#FFF2CD',
            color: 'black',
            textAlign: 'center',
            border: '1px solid #FFE69C',
          }}
        >
          Please enable location services for best results.
        </div>
      )}
    </>
  );
};

export default LocationWarning;
