import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'iconsax-react';

const ShowItemPerPageOptions = [5, 10, 30, 50, 70, 100];

const DEFAULT_ITEMS_PER_PAGE = 6;
const DEFAULT_PAGE_INDEX = 0;

const Pagination = ({
  items,
  isTable = true,
  renderComponent,
  totalSelectedPages = 100,
}) => {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);

  const sliceStart = pageIndex * itemsPerPage;
  const sliceEnd = sliceStart + itemsPerPage;

  const [currItems, setCurrItems] = useState(
    items?.slice(sliceStart, sliceEnd)
  );

  const numberOfBottomPages = Math.ceil((items ?? []).length / itemsPerPage);

  useEffect(() => {
    const updatedCurrItems = items?.slice(sliceStart, sliceEnd);

    if (updatedCurrItems?.length === 0 && numberOfBottomPages !== 0) {
      setPageIndex(numberOfBottomPages - 1);
    }
  }, [items, numberOfBottomPages]);

  useEffect(() => {
    setCurrItems(items?.slice(sliceStart, sliceEnd));
  }, [items, items?.length, sliceStart, sliceEnd, pageIndex]);

  useEffect(() => {
    setCurrItems(items?.slice(sliceStart, sliceEnd));
  }, [pageIndex]);

  const totalPages = Math.ceil((items ?? []).length / itemsPerPage);

  let pageArray = [0, 1, 2]
    .map((v) => pageIndex + 1 + v)
    .filter((page) => page > 0 && page <= totalPages);

  if (pageIndex > totalPages - 4) {
    pageArray = [-1, 0, 1, 2]
      .map((v) => pageIndex + 1 + v)
      .filter((page) => page > 0 && page <= totalPages);
  }
  if (pageIndex > totalPages - 3) {
    pageArray = [-2, -1, 0, 1, 2]
      .map((v) => pageIndex + 1 + v)
      .filter((page) => page > 0 && page <= totalPages);
  }
  if (pageIndex > totalPages - 2) {
    pageArray = [-3, -2, -1, 0, 1, 2, 3]
      .map((v) => pageIndex + 1 + v)
      .filter((page) => page > 0 && page <= totalPages);
  }

  const goToPage = (index) => {
    if (index >= 0 && index <= totalPages - 1) {
      setPageIndex(index);
    }
  };

  const onNext = () => {
    if (pageIndex < totalPages - 1) {
      setPageIndex((index) => index + 1);
    }
  };

  const onPrevious = () => {
    if (pageIndex - 1 >= 0) {
      setPageIndex((index) => index - 1);
    }
  };

  return (
    <>
      {renderComponent(currItems)}
      <div style={isTable ? { display: 'table-caption' } : {}} className='row'>
        <div className='col-12 mb-4 pagination-col mt-5'>
          <ul className='pagination mx-auto'>
            <li className='page-item'>
              <Link onClick={onPrevious} className='page-link' href='#'>
                <ArrowLeft size='14' color='#020617' />
              </Link>
            </li>
            {pageArray.map((page) => (
              <li color='#020617' className='page-item'>
                <Link
                  onClick={() => goToPage(page - 1)}
                  style={
                    pageIndex + 1 === page
                      ? { backgroundColor: '#76c4f1', color: 'white' }
                      : {}
                  }
                  className='page-link'
                  href='#'
                >
                  {page}
                </Link>
              </li>
            ))}
            <li className='page-item'>
              <Link onClick={onNext} className='page-link' href='#'>
                <ArrowRight size='14' color='#020617' />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Pagination;
