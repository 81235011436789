import { ArrowLeft } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header(props) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      setUser(parsedUser);
    }
  }, []);

  return (
    <div className='d-flex align-items-center header-bar justify-content-between mb-48'>
      <div className='d-flex align-items-center gap-3 '>
        <Link to={props.href} style={{ display: `${props.Display}` }}>
          <ArrowLeft size={'24'} color='#020617' />
        </Link>
        <h2 className='header-title mb-0'>{props.headerTitle}</h2>
      </div>
      <div className='d-flex align-items-center gap-3 '>
        {user && (
          <Link className='user-img-click' to='/dashboard/settings'>
            <img
              className='user-img '
              src='../../assets/images/dummy-img.png'
              alt='user-img'
              onError={(e) => {
                e.target.onError = null;
                e.target.src = '../../assets/images/dummy-img.png';
              }}
            />
          </Link>
        )}
      </div>
    </div>
  );
}
