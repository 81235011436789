import axios from './axios';

const addJob = async (data) => {
  try {
    const response = await axios.post('/job', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const searchJobs = async (queryParams) => {
  try {
    let endpoint = '/job';

    if (queryParams) endpoint += `?${queryParams}`;

    const response = await axios.get(endpoint);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const searchJobsByRecruiterId = async (id, queryParams) => {
  try {
    let endpoint = `/job/recruiter/${id}`;

    if (queryParams) endpoint += `?${queryParams}`;

    const response = await axios.get(endpoint);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getJobById = async (id) => {
  try {
    const response = await axios.get(`/job/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getMyJobs = async () => {
  try {
    const response = await axios.get('/job/me');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getMyAcceptedJobs = async () => {
  try {
    const response = await axios.get('/job/me/accepted');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateMyJob = async (id, data) => {
  try {
    const response = await axios.patch(`/job/me/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const markJobAsComplete = async (id) => {
  try {
    const response = await axios.patch(`/job/complete/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const addJobReview = async (id, data) => {
  try {
    const response = await axios.post(`/job/review/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteMyJob = async (id) => {
  try {
    const response = await axios.delete(`/job/me/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const jobService = {
  addJob,
  searchJobs,
  searchJobsByRecruiterId,
  getJobById,
  getMyJobs,
  getMyAcceptedJobs,
  updateMyJob,
  markJobAsComplete,
  addJobReview,
  deleteMyJob,
};

export default jobService;
