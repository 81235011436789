import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import SignIn from './Auth/Signin';
import Dashboard from './dashboard';
import SignUp from './Auth/Signup';
import 'leaflet/dist/leaflet.css';
import LocationWarning from './components/LocationWarning';
import SupportButton from './components/SupportButton';

const LocationWrapper = ({ children }) => {
  const location = useLocation();
  const showLocationWarning = !['/', '/signUp'].includes(location.pathname);

  return (
    <>
      {showLocationWarning && <LocationWarning />}
      {children}
    </>
  );
};

function App() {
  return (
    <div className='app'>
      <BrowserRouter>
        <LocationWrapper>
          <Routes>
            <Route exact path='/' element={<SignIn />} />
            <Route exact path='/signUp' element={<SignUp />} />
            <Route path='/dashboard/*' element={<Dashboard />} />
          </Routes>
        </LocationWrapper>
      </BrowserRouter>
      <SupportButton />
    </div>
  );
}

export default App;
