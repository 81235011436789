import toast from 'react-hot-toast';
import jobOfferService from '../services/job-offer-service';
import { apiHost } from '../constants';

export default function PaymentInformation({ job, jobOffer }) {
  const handlePayOffer = async () => {
    try {
      const response = await jobOfferService.payJobOffer(jobOffer.id);

      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.success('You will be redirected for payment shortly.');
        window.location.href = response.stripeUrl;
      }
    } catch (error) {
      toast.error('Failed to process payment');
    }
  };

  return (
    <>
      <div className='modal place-order-modal' id='PaymentInformation'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content '>
            <div className='modal-header flex-column gap-2 align-items-center justify-content-center  p-32 slate-50'>
              <h2 className='modal-title line-height text-center '>
                Acceptance and Payment Confirmation
              </h2>
              <p className='mb-0 text-center slate-500 font-normal'>
                You’re almost done. You will be redirected to our payment page
                to accept the offer and get started.
              </p>
            </div>
            <div className='modal-body advance-search-modal position-relative p-0 text-center'>
              <div className='auth-form d-flex flex-column gap-32 p-32 '>
                <div className='d-flex flex-column gap-4'>
                  <label className='mb-0 text-start'>Job Offer Details</label>
                  <div className='product-detail-modal   text-start overflow-hidden '>
                    <ul className='d-flex align-items-center gap-3  '>
                      <li>
                        <img
                          className='rounded-circle '
                          height={'48'}
                          width={'48'}
                          src={`${apiHost}/${job?.JobImages[0]?.url}`}
                          alt='product-img'
                        />
                      </li>
                      <li className='text-start d-flex flex-column gap-2'>
                        <p className='mb-0 font-size14 line-height'>
                          {job?.title}
                        </p>
                        <span
                          className='slate-500 line-height d-flex align-items-center gap-2'
                          style={{ fontSize: '12px' }}
                        >
                          ${jobOffer?.offeredPrice}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center modal-footer confirm-modal-footer gap-3 p-32 justify-content-between '>
              <button
                type='button'
                className='btn button2'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>

              <button
                type='button'
                className='btn btn-danger'
                onClick={handlePayOffer}
              >
                Accept & Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
