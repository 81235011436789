import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Message, SearchNormal1, Send } from 'iconsax-react';
import Header from '../../layout/header';
import CustomOffers from '../../components/Messages/CustomOffers';
import CustomOffersQuantity from '../../components/Messages/CustomOffersQuantity';
import userService from '../../services/user-service';
import {
  collection,
  doc,
  addDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';
import CustomOfferMessage from '../../components/Messages/CustomOfferMessage';
import { UserRolesEnum } from '../../constants';
import jobOfferService from '../../services/job-offer-service';

export default function Messages() {
  const [myUser, setMyUser] = useState('');
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [jobOffers, setJobOffers] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const chatMessagesRef = useRef(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [customPrice, setCustomPrice] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setMyUser(user);
    }

    const fetchUsers = async () => {
      const response = await userService.getAllUsers();
      if (response?.error) {
        toast.error(response.error);
      } else {
        setUsers(response || []);
      }
    };

    fetchUsers();
  }, []);

  const fetchJobOffersBetweenRecruiterAndFreelancer = async (
    recruiterId,
    freelancerId
  ) => {
    const response =
      await jobOfferService.getJobOffersBetweenRecruiterAndFreelancer(
        recruiterId,
        freelancerId
      );
    if (response?.error) {
      toast.error(response.error);
    } else {
      setJobOffers(response.jobOffers);
    }
  };

  useEffect(() => {
    if (myUser.id) {
      const q = query(
        collection(db, 'chats'),
        where('participants', 'array-contains', myUser.id)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const chatsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChats(chatsList);

        const queryParams = new URLSearchParams(location.search);
        const recruiterId = parseInt(queryParams.get('recruiterId'));

        if (recruiterId && myUser.id) {
          const existingChat = chatsList.find(
            (chat) =>
              chat.participants.includes(myUser.id) &&
              chat.participants.includes(recruiterId)
          );
          if (existingChat) {
            setSelectedChat(existingChat);
            getChatMessages(existingChat);
          } else {
            const tempChat = {
              participants: [myUser.id, recruiterId],
              messages: [],
              unreadMessages: {
                [myUser.id]: false,
                [recruiterId]: true,
              },
            };
            setSelectedChat(tempChat);
            setChatMessages([]);
          }
        }
      });

      return () => unsubscribe();
    }
  }, [myUser, location.search]);

  const getChatMessages = async (chat) => {
    setSelectedChat(chat);
    const q = query(
      collection(db, 'chats', chat.id, 'messages'),
      orderBy('createdAt', 'asc')
    );
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const messagesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChatMessages(messagesList);

      const newMessage = querySnapshot
        .docChanges()
        .find((change) => change.type === 'added');

      if (newMessage?.doc.data().offerId) {
        const otherUserId = chat.participants.find((id) => id !== myUser.id);
        await fetchJobOffersBetweenRecruiterAndFreelancer(
          myUser.role === 'Recruiter' ? myUser.id : otherUserId,
          myUser.role === 'Freelancer' ? myUser.id : otherUserId
        );
      }

      if (chat.unreadMessages && chat.unreadMessages[myUser.id]) {
        updateDoc(doc(db, 'chats', chat.id), {
          [`unreadMessages.${myUser.id}`]: false,
        });
      }
    });

    const otherUserId = chat.participants.find((id) => id !== myUser.id);
    const otherUser = users.find((user) => user.id === otherUserId);

    if (
      (myUser.role === 'Recruiter' && otherUser?.role === 'Freelancer') ||
      (myUser.role === 'Freelancer' && otherUser?.role === 'Recruiter')
    ) {
      const recruiterId = myUser.role === 'Recruiter' ? myUser.id : otherUserId;
      const freelancerId =
        myUser.role === 'Freelancer' ? myUser.id : otherUserId;
      await fetchJobOffersBetweenRecruiterAndFreelancer(
        recruiterId,
        freelancerId
      );
    }

    return () => unsubscribe();
  };

  const sendMessage = async (event, customMessage = null) => {
    if (event) event.preventDefault();

    const messageToSend = customMessage ? '' : newMessage.trim();
    const offerId = customMessage?.offerId || null;

    if (!messageToSend && !offerId) return;

    const receiverId = selectedChat
      ? selectedChat.participants.find((id) => id !== myUser.id)
      : users.find((user) => user.id === newMessage.receiverId)?.id;

    if (!receiverId) return;

    setNewMessage('');

    let chatId = selectedChat?.id;

    if (!chatId) {
      const newChat = {
        participants: [myUser.id, receiverId],
        createdAt: serverTimestamp(),
        unreadMessages: {
          [myUser.id]: false,
          [receiverId]: true,
        },
      };
      const chatDocRef = await addDoc(collection(db, 'chats'), newChat);
      chatId = chatDocRef.id;
      const createdChat = { id: chatDocRef.id, ...newChat };
      setChats((prevChats) => [...prevChats, createdChat]);
      setSelectedChat(createdChat);
      setChatMessages([]);

      getChatMessages(createdChat);
    }

    const messagesCollectionRef = collection(db, 'chats', chatId, 'messages');
    const newMessageDoc = {
      senderId: myUser.id,
      text: messageToSend,
      offerId: offerId,
      createdAt: serverTimestamp(),
    };
    await addDoc(messagesCollectionRef, newMessageDoc);

    await updateDoc(doc(db, 'chats', chatId), {
      [`unreadMessages.${receiverId}`]: true,
      LastMessage: {
        messageText: messageToSend || 'Custom Offer',
        createdAt: new Date(),
      },
    });

    if (customMessage) {
      await fetchJobOffersBetweenRecruiterAndFreelancer(
        myUser.role === 'Recruiter'
          ? myUser.id
          : selectedChat.participants.find((id) => id !== myUser.id),
        myUser.role === 'Freelancer'
          ? myUser.id
          : selectedChat.participants.find((id) => id !== myUser.id)
      );
    }

    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const createCustomOffer = async () => {
    try {
      const response = await jobOfferService.createJobOffer(selectedJob.id, {
        offeredPrice: customPrice,
      });

      if (response?.error) {
        toast.error(response.error);
      } else {
        await fetchJobOffersBetweenRecruiterAndFreelancer(
          myUser.role === 'Recruiter'
            ? myUser.id
            : selectedChat.participants.find((id) => id !== myUser.id),
          myUser.role === 'Freelancer'
            ? myUser.id
            : selectedChat.participants.find((id) => id !== myUser.id)
        );
        const offerId = response.jobOffer.id;
        await sendMessage(null, { offerId });
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error('Failed to send custom job offer');
    }
  };

  const formatDateUTC = (date) => {
    try {
      const parsedDate = date instanceof Date ? date : date.toDate();
      if (isNaN(parsedDate)) {
        console.error(`Invalid date: ${date}`);
        return 'Invalid date';
      }

      const formatter = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      });

      const formattedDate = formatter.format(parsedDate);

      return formattedDate.replace('AM', 'am').replace('PM', 'pm');
    } catch (error) {
      console.error(`Error formatting date: ${date}`, error);
      return 'Invalid date';
    }
  };

  const formatDateDifferenceShort = (date) => {
    try {
      const parsedDate = date instanceof Date ? date : date.toDate();
      const now = new Date();

      const diffYears = differenceInYears(now, parsedDate);
      if (diffYears !== 0) return `${Math.abs(diffYears)}y`;

      const diffMonths = differenceInMonths(now, parsedDate);
      if (diffMonths !== 0) return `${Math.abs(diffMonths)}mo`;

      const diffDays = differenceInDays(now, parsedDate);
      if (diffDays !== 0) return `${Math.abs(diffDays)}d`;

      const diffHours = differenceInHours(now, parsedDate);
      if (diffHours !== 0) return `${Math.abs(diffHours)}h`;

      const diffMinutes = differenceInMinutes(now, parsedDate);
      if (diffMinutes !== 0) return `${Math.abs(diffMinutes)}m`;

      return 'Just now';
    } catch (error) {
      console.error(`Error formatting date: ${date}`, error);
      return 'Invalid date';
    }
  };

  const getFilteredUsers = () => {
    return searchTerm.trim() === ''
      ? users.filter((user) => user.id !== myUser.id && user.role !== 'Admin')
      : users.filter(
          (user) =>
            user.id !== myUser.id &&
            user.role !== 'Admin' &&
            `${user.firstName} ${user.lastName}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setIsDropdownVisible(true);
  };

  const handleSearchFocus = () => {
    setIsDropdownVisible(true);
  };

  const handleSearchBlur = () => {
    setTimeout(() => setIsDropdownVisible(false), 100);
  };

  const isUserInChat = (userId) => {
    return chats.some((chat) => chat.participants.includes(userId));
  };

  const handleUserClick = (event, user) => {
    event.preventDefault();
    const existingChat = chats.find(
      (chat) =>
        chat.participants.includes(myUser.id) &&
        chat.participants.includes(user.id)
    );
    if (existingChat) {
      setSelectedChat(existingChat);
      getChatMessages(existingChat);
    } else {
      const tempChat = {
        participants: [myUser.id, user.id],
        messages: [],
        unreadMessages: {
          [myUser.id]: false,
          [user.id]: true,
        },
      };
      setSelectedChat(tempChat);
      setChatMessages([]);
    }
    setIsDropdownVisible(false);
  };

  return (
    <>
      <Header Display='block' href='/dashboard' headerTitle='All Messages' />
      <div className='bg-white chat-module d-flex align-items-start radius-16 overflow-hidden'>
        <div className='message-sidebar'>
          <div className='relative chat-search'>
            <input
              className='form-control'
              placeholder='Search something...'
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
            />
            <span className='search-icon'>
              <SearchNormal1 size={'20'} color='#38B1ED' />
            </span>
          </div>

          {isDropdownVisible && (
            <ul className='search-dropdown'>
              {getFilteredUsers().map((user) => (
                <li
                  key={user.id}
                  className={`d-flex align-items-center gap-3 ${
                    isUserInChat(user.id) ? 'existing-chat' : ''
                  }`}
                  onMouseDown={(event) => handleUserClick(event, user)}
                >
                  <div>
                    <img
                      src={user.imageUrl || '../../assets/images/dummy-img.png'}
                      alt='user'
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = '../../assets/images/dummy-img.png';
                      }}
                      height={'40px'}
                      width={'40px'}
                      className='rounded-circle p-1'
                      style={{ border: '1px solid #38B1ED' }}
                    />
                  </div>
                  <div className='grow w-100 gap-2 d-flex flex-column'>
                    <div className='d-flex align-items-start justify-content-between'>
                      <h4 className='font-size14 line-height mb-0'>
                        {user.firstName} {user.lastName}
                      </h4>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}

          <ul className=''>
            {chats?.map((chat) => (
              <li
                key={chat?.id}
                className={`d-flex align-items-center gap-3`}
                style={
                  chat.unreadMessages[myUser.id]
                    ? {
                        backgroundColor: 'lightblue',
                      }
                    : {}
                }
                onClick={() => getChatMessages(chat)}
              >
                <div>
                  <img
                    src='../../assets/images/dummy-img.png'
                    alt='user'
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = '../../assets/images/dummy-img.png';
                    }}
                    height={'40px'}
                    width={'40px'}
                    className='rounded-circle p-1 '
                    style={{ border: '1px solid #38B1ED' }}
                  />
                </div>
                <div className='grow w-100 gap-2 d-flex flex-column'>
                  <div className='d-flex align-items-start justify-content-between'>
                    <h4 className='font-size14 line-height mb-0'>
                      {myUser.id === chat.participants[0]
                        ? `${
                            users.find(
                              (user) => user.id === chat.participants[1]
                            )?.firstName
                          } ${
                            users.find(
                              (user) => user.id === chat.participants[1]
                            )?.lastName
                          }`
                        : `${
                            users.find(
                              (user) => user.id === chat.participants[0]
                            )?.firstName
                          } ${
                            users.find(
                              (user) => user.id === chat.participants[0]
                            )?.lastName
                          }`}
                    </h4>
                    <p
                      className={`mb-0 line-height slate-500 font-normal`}
                      style={{ fontSize: '12px' }}
                    >
                      {chat.LastMessage && chat.LastMessage.createdAt
                        ? formatDateDifferenceShort(chat.LastMessage.createdAt)
                        : ''}
                    </p>
                  </div>

                  <p
                    className={`mb-0 message-line slate-500 line-height font-normal`}
                    style={{ fontSize: '12px' }}
                  >
                    {typeof chat?.LastMessage?.messageText === 'object'
                      ? ''
                      : chat?.LastMessage?.messageText}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className=' chat-box p-4 '>
          {selectedChat ? (
            <div className='h-full'>
              <div className='d-flex align-items-center justify-content-between inbox-chat'>
                <div className='d-flex align-items-center user-online gap-3 '>
                  <Link>
                    <img
                      src='../../assets/images/dummy-img.png'
                      height='56px'
                      width={'56px'}
                      className='p-1 rounded-circle '
                      fill
                      alt='user'
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = '../../assets/images/dummy-img.png';
                      }}
                      style={{ border: '1px solid #38B1ED' }}
                    />
                  </Link>

                  <div className='d-flex flex-column gap-2'>
                    <p className='mb-0 line-height'>
                      {myUser.id === selectedChat.participants[0]
                        ? `${
                            users.find(
                              (user) => user.id === selectedChat.participants[1]
                            )?.firstName
                          } ${
                            users.find(
                              (user) => user.id === selectedChat.participants[1]
                            )?.lastName
                          }`
                        : `${
                            users.find(
                              (user) => user.id === selectedChat.participants[0]
                            )?.firstName
                          } ${
                            users.find(
                              (user) => user.id === selectedChat.participants[0]
                            )?.lastName
                          }`}
                    </p>
                    <p className='online-tag line-height mb-0'>{true}</p>
                  </div>
                </div>
              </div>

              <div
                className='chat-messages-container'
                ref={chatMessagesRef}
                style={{ overflowY: 'auto', height: '500px' }}
              >
                <ul className='d-flex flex-column gap-4 texting-ul'>
                  {chatMessages?.map((message, key) => {
                    const jobOffer = message.offerId
                      ? jobOffers
                          .flatMap((job) =>
                            job.JobOffers.map((offer) => ({
                              job,
                              offer,
                            }))
                          )
                          .find(({ offer }) => offer.id === message.offerId)
                      : null;
                    return (
                      <li
                        key={key}
                        className={`d-flex align-items-start gap-3 ${
                          message.senderId === myUser.id
                            ? 'justify-content-end'
                            : 'justify-content-start'
                        }`}
                      >
                        {message.senderId !== myUser.id && (
                          <img
                            src='../../assets/images/dummy-img.png'
                            fill
                            height={'32px'}
                            width={'32px'}
                            className='rounded-circle p-1'
                            alt='user'
                            onError={(e) => {
                              e.target.onError = null;
                              e.target.src =
                                '../../assets/images/dummy-img.png';
                            }}
                            style={{ border: '1px solid #38B1ED' }}
                          />
                        )}
                        <div className=' w-full'>
                          <div
                            className={` w-full ${
                              message.senderId !== myUser.id
                                ? 'bg-[#F2F4F7]'
                                : 'bg-[#2F3845]'
                            } py-[10px] px-[12px] mt-[6px]  rounded-b-lg ${
                              message.senderId !== myUser.id
                                ? 'rounded-r-lg'
                                : 'rounded-l-lg'
                            }`}
                          >
                            <div
                              className={`${
                                message.senderId === myUser.id
                                  ? 'sender-text'
                                  : 'receiver-text'
                              } texting d-flex flex-column gap-3`}
                            >
                              {jobOffer ? (
                                <CustomOfferMessage
                                  job={jobOffer.job}
                                  jobOffer={jobOffer.offer}
                                  isSender={message?.senderId === myUser.id}
                                />
                              ) : (
                                <p
                                  className={`font-size14 mb-0  font-normal ${
                                    message.senderId !== myUser.id
                                      ? 'text-[#101828]'
                                      : ''
                                  }`}
                                >
                                  {message?.text}
                                </p>
                              )}

                              <span className=''>
                                {message && message.createdAt
                                  ? formatDateUTC(message.createdAt)
                                  : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className='chat-input '>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter a message...'
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      sendMessage(e);
                    }
                  }}
                />
                <div className='d-flex align-items-center'>
                  {myUser.role === UserRolesEnum.FREELANCER && (
                    <span
                      className='cursor-pointer font-size12 violet'
                      data-bs-toggle='modal'
                      data-bs-target='#CustomOffers'
                    >
                      Custom Offer
                    </span>
                  )}
                  <Send
                    className={newMessage.trim() ? 'cursor-pointer' : ''}
                    color={newMessage.trim() ? '#38B1ED' : '#717e85'}
                    variant='Bold'
                    size={'20'}
                    onClick={sendMessage}
                    disabled={!newMessage.trim()}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className=' select-inbox text-center mx-auto d-flex flex-column align-items-center justify-content-center h-100 '>
              <Message size='44' className='mb-4' color='#38B1ED' />
              <h4 className='mb-2'>Select a Conversation</h4>
              <p className='mb-0 px-4 '>
                Select a conversation to start messaging.
              </p>
            </div>
          )}
        </div>
      </div>

      {myUser.role === UserRolesEnum.FREELANCER && (
        <>
          <CustomOffers
            recruiter={
              users?.length > 0 &&
              users?.find(
                (user) =>
                  user?.id ===
                  (myUser?.id === selectedChat?.participants[0]
                    ? selectedChat?.participants[1]
                    : selectedChat?.participants[0])
              )
            }
            setSelectedJob={setSelectedJob}
          />
          <CustomOffersQuantity
            customPrice={customPrice}
            setCustomPrice={setCustomPrice}
            createCustomOffer={createCustomOffer}
          />
        </>
      )}
    </>
  );
}
