import React, { useState } from 'react';
import { Star1 } from 'iconsax-react';
import ReactStars from 'react-rating-stars-component';
import toast from 'react-hot-toast';
import jobService from '../../services/job-service';

export default function LeaveRatingReview({ job }) {
  const [rating, setRating] = useState(0);

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const handleSubmit = async () => {
    if (rating) {
      const response = await jobService.addJobReview(job.id, { rating });

      if (response?.error) toast.error(response.error);
      else {
        toast.success(response?.message);
      }
    } else {
      toast.error('Rating cannot be empty');
    }
  };

  return (
    <>
      <div className='modal place-order-modal' id='LeaveRatingReview'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header flex-column border-0  gap-2 align-items-center justify-content-center p-32 '>
              <h2 className='modal-title line-height text-center '>
                Rating and Review
              </h2>
              <p className='mb-0 text-center slate-500 font-normal line-height-200'>
                Did you enjoy the services? Make sure to leave feedback on your
                experience.
              </p>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={32}
                isHalf={true}
                value={rating}
                emptyIcon={<Star1 color='#facc15' />}
                halfIcon={<i className='fa fa-star-half-alt'></i>}
                fullIcon={<Star1 color='#facc15' variant='Bold' />}
                activeColor='#ffd700'
              />
            </div>

            <div className='d-flex align-items-center slate-50 modal-footer confirm-modal-footer gap-3 p-32 justify-content-between '>
              <button
                type='button'
                className='btn button2'
                data-bs-dismiss='modal'
              >
                Do Later
              </button>

              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
