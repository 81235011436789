import React, { useState, useRef, useEffect } from 'react';
import Header from '../../layout/header';
import { Gallery } from 'iconsax-react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { difficultyLevels, imageSizeLimit } from '../../constants';
import jobService from '../../services/job-service';
import heic2any from 'heic2any';

export default function AddJob(props) {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [newJob, setNewJob] = useState({
    title: '',
    description: '',
    price: '10.00',
    radius: '',
    difficultyLevel: '',
  });
  const [uploadedImages, setUploadedImages] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    const getUserLocation = () => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              type: 'Point',
              coordinates: [
                position.coords.longitude,
                position.coords.latitude,
              ],
            });
          },
          (error) => {
            toast.error(
              'Location access denied. Please enable location services.'
            );
          }
        );
      } else {
        toast.error('Geolocation is not supported by this browser.');
      }
    };

    getUserLocation();
  }, []);

  const handlePriceChange = (e) => {
    const value = e.target.value
      .replace(/[^\d.]/g, '')
      .replace(/^(\d*\.?\d{0,2}).*$/, '$1');
    setNewJob({ ...newJob, price: value });
  };

  const handleImageUpload = async (files) => {
    const validFileTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/webp',
      'image/heic',
      'image/heif',
    ];
    const allowedFileTypesMessage =
      'Only .jpg, .jpeg, .png, .gif, .bmp, .webp, .heic, and .heif files are allowed!';
    const maxFileSize = imageSizeLimit * 1024 * 1024;

    const images = [];
    for (const file of files) {
      if (!validFileTypes.includes(file.type)) {
        toast.error(`File type not allowed. ${allowedFileTypesMessage}`);
        continue;
      } else if (file.size > maxFileSize) {
        toast.error(`File size should not exceed 10 MB.`);
        continue;
      }

      if (file.type === 'image/heic' || file.type === 'image/heif') {
        try {
          toast.loading('Converting HEIC image...');
          const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
          });
          const convertedFile = new File(
            [convertedBlob],
            file.name.replace(/\.[^/.]+$/, '.jpg'),
            { type: 'image/jpeg' }
          );
          images.push(convertedFile);
          toast.dismiss();
        } catch (error) {
          toast.error(`Error converting file: ${file.name}`);
        }
      } else {
        images.push(file);
      }
    }

    const totalUploadedImages = uploadedImages.length + images.length;
    if (totalUploadedImages <= 8) {
      setUploadedImages([...uploadedImages, ...images]);
    } else {
      toast.error('You can upload a maximum of 8 images');
    }
  };

  const openFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleImageUpload(files);
  };

  const addNewJob = async () => {
    if (
      !newJob.title ||
      !newJob.description ||
      !newJob.price ||
      !newJob.radius ||
      !newJob.difficultyLevel
    ) {
      return toast.error('Please fill out all required fields');
    }

    if (parseFloat(newJob.price) < 10) {
      return toast.error('Price must be at least $10');
    }

    if (!userLocation) {
      return toast.error('Location is required to create a job');
    }

    if (uploadedImages.length === 0) {
      return toast.error('Please upload at least one image');
    }

    const formData = new FormData();
    formData.append('title', newJob.title);
    formData.append('description', newJob.description);
    formData.append('price', newJob.price);
    formData.append('radius', newJob.radius);
    formData.append('difficultyLevel', newJob.difficultyLevel);
    formData.append('location', JSON.stringify(userLocation));

    uploadedImages.forEach((file) => {
      formData.append('images', file);
    });

    try {
      const response = await jobService.addJob(formData);
      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.success(response?.message);
        navigate('/dashboard/my-jobs');
      }
    } catch (error) {
      toast.error('Failed to add job');
    }
  };

  return (
    <>
      <Header
        Display='block'
        href='/dashboard/my-jobs'
        headerTitle='Add a Job'
      />
      <div className='container-fluid px-0'>
        <div className='row '>
          <div className='col-12 col-xl-6 add-product-form'>
            <div className='add-product-form bg-white p-4 accordion radius-12'>
              <div className='auth-form d-flex flex-column gap-32'>
                <select
                  required
                  className='form-select form-control px-4'
                  onChange={(e) =>
                    setNewJob({ ...newJob, difficultyLevel: e.target.value })
                  }
                  defaultValue=''
                >
                  <option selected disabled value=''>
                    Difficulty Level
                  </option>
                  {difficultyLevels.map((difficultyLevel) => (
                    <option key={difficultyLevel} value={difficultyLevel}>
                      {difficultyLevel}
                    </option>
                  ))}
                </select>

                <div className='gap-4 d-flex flex-column align-items-start'>
                  <label className=''>Job Title</label>
                  <input
                    type='text'
                    required
                    className='form-control flex-grow-1 px-4'
                    placeholder='Title of Job'
                    value={newJob.title}
                    onChange={(e) =>
                      setNewJob({ ...newJob, title: e.target.value })
                    }
                  />
                </div>

                <div className='d-flex align-items-start location-input flex-grow-1 flex-column gap-3 position-relative'>
                  <label className=''>Job Description</label>
                  <textarea
                    className='form-control border-0 p-1'
                    placeholder='Enter something...'
                    style={{ borderRadius: '0px' }}
                    rows={6}
                    value={newJob.description}
                    onChange={(e) =>
                      setNewJob({ ...newJob, description: e.target.value })
                    }
                  ></textarea>
                </div>

                <div className='gap-4 d-flex flex-column align-items-start'>
                  <label className=''>Job Radius (mi)</label>
                  <input
                    type='number'
                    required
                    className='form-control flex-grow-1 px-4'
                    placeholder='Radius'
                    value={newJob.radius}
                    onChange={(e) =>
                      setNewJob({ ...newJob, radius: e.target.value })
                    }
                  />
                </div>

                <div className='gap-4 d-flex flex-column align-items-start'>
                  <label className=''>Job Price (USD)</label>
                  <input
                    type='text'
                    required
                    className='form-control flex-grow-1 px-4'
                    placeholder='Job Price'
                    value={newJob.price}
                    onChange={handlePriceChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-xl-6 add-product-img d-flex flex-column justify-content-between '>
            <div className='bg-white radius-12 d-flex flex-column gap-32 p-24'>
              <p className='mb-0 line-height'>Job Images</p>

              <div
                className='upload-file position-relative p-32 d-flex flex-column radius-12 align-items-center gap-3'
                onClick={openFileUpload}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{ cursor: 'pointer' }}
              >
                <Gallery size='64' color='#cbd5e1' variant='Bulk' />
                <p className='slate-500 text-center  font-size14 font-normal'>
                  Drag and drop or click to browse images from your gallery to
                  add to your job.
                </p>
                <input
                  type='file'
                  accept='.jpg,.jpeg,.png,.gif,.bmp,.webp,.heic,.heif'
                  onChange={async (e) =>
                    await handleImageUpload(e.target.files)
                  }
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  multiple
                />
              </div>

              <ul className='uploaded-product gap-3 d-grid'>
                {uploadedImages.map((file, index) => (
                  <li className='position-relative radius-12' key={index}>
                    <img
                      className='img-fluid'
                      src={URL.createObjectURL(file)}
                      alt={`Job Images`}
                    />
                    <span
                      className='cross-icon'
                      onClick={() => {
                        const updatedImages = [...uploadedImages];
                        updatedImages.splice(index, 1);
                        setUploadedImages(updatedImages);
                      }}
                      style={{
                        color: 'white',
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                      }}
                    >
                      &#10006;
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <Link
              className='btn ms-auto '
              style={{ maxWidth: '180px' }}
              onClick={addNewJob}
            >
              Add a Job
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
