import axios from './axios';

const supportRequest = async (data) => {
  try {
    const response = await axios.post(`/email/supportRequest`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const emailService = {
  supportRequest,
};

export default emailService;
