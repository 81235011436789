import { Verify } from 'iconsax-react';
import { apiHost } from '../../constants';

export default function OrderComplete({ job }) {
  return (
    <>
      <div className='modal place-order-modal' id='OrderComplete'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content '>
            <div className='modal-header flex-column gap-2 align-items-center justify-content-center  p-32 slate-50'>
              <div className='bg-white icon-span d-flex align-items-center justify-content-center rounded-circle'>
                <Verify color='#84cc16' size={'48'} variant='Bulk' />
              </div>
              <h2 className='modal-title line-height text-center '>
                Job Completed
              </h2>
              <p className='mb-0 text-center slate-500 font-normal line-height-200'>
                Thank you for using our services. Don’t forget to leave a nice
                rating.
              </p>
            </div>
            <div className='modal-body advance-search-modal position-relative p-0 text-center'>
              <div className='auth-form d-flex flex-column gap-32 p-32 '>
                <div className='d-flex flex-column gap-4'>
                  <div className='product-detail-modal   text-start overflow-hidden '>
                    <ul className='d-flex align-items-center gap-3  '>
                      <li>
                        <img
                          className='rounded-circle'
                          src={`${apiHost}/${job?.JobImages[0]?.url}`}
                          height='48px'
                          width='48px'
                          alt=''
                        />
                      </li>
                      <li className='text-start d-flex flex-column gap-2'>
                        <p className='mb-0 font-size14 line-height'>
                          {job.title}
                        </p>
                        <span
                          className='slate-500 line-height d-flex align-items-center gap-2'
                          style={{ fontSize: '12px' }}
                        >
                          ${job?.JobOffers[0]?.offeredPrice}
                        </span>
                      </li>
                    </ul>

                    <p className='font-size14 mb-0 slate-50 border-tb font-normal'>
                      <span
                        className='slate-500 me-3 pe-3 font-normal'
                        style={{ borderRight: '1px solid #E2E8F0' }}
                      >
                        To
                      </span>
                      {job.Recruiter.firstName} {job.Recruiter.lastName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center slate-50 modal-footer confirm-modal-footer gap-3 p-32 justify-content-between '>
              <button
                type='button'
                className='btn button2'
                data-bs-dismiss='modal'
              >
                Close Window
              </button>

              <button
                type='button'
                className='btn btn-danger'
                data-bs-toggle='modal'
                data-bs-target='#LeaveRatingReview'
              >
                Leave Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
