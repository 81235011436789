import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { SearchNormal1, Star1 } from 'iconsax-react';
import Pagination from '../../components/Pagination';
import Header from '../../layout/header';
import userService from '../../services/user-service';
import { UserRolesEnum } from '../../constants';
import ClearPaymentModal from '../../components/AdminPanel/ClearPayment';

export default function AdminPanel(props) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [freelancers, setFreelancers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await userService.getAllUsers();
        if (response?.error) {
          toast.error(response.error);
        } else {
          setUsers(response);
          setFreelancers(
            response.filter((user) => user.role === UserRolesEnum.FREELANCER)
          );
        }
      } catch (error) {
        toast.error('Failed to fetch users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = useMemo(() => {
    return users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [users, searchText]);

  const handleClearPayment = async (userId, amount) => {
    try {
      const response = await userService.clearPendingPayment(userId, {
        amount,
      });
      if (response?.error) {
        toast.error(response.error);
      } else {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId
              ? {
                  ...user,
                  pendingPayments:
                    parseFloat(user.pendingPayments) - parseFloat(amount),
                  totalEarnings:
                    parseFloat(user.totalEarnings) + parseFloat(amount),
                }
              : user
          )
        );
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error('Failed to clear pending payment');
    }
  };

  const renderUserItems = (users) => {
    if (loading) {
      return <div className='text-info'>Loading users...</div>;
    }

    return (
      <tbody>
        {users.map((user) => (
          <tr key={user.id}>
            <td>
              <div className='d-flex flex-column gap-2'>
                <p className='mb-0 font-size14 line-height font-normal'>
                  {user.firstName}
                </p>
              </div>
            </td>
            <td>
              <div className='d-flex flex-column gap-2'>
                <p className='mb-0 font-size14 line-height font-normal'>
                  {user.lastName}
                </p>
              </div>
            </td>
            <td>
              <div className='d-flex flex-column gap-2'>
                <p className='mb-0 font-size14 line-height font-normal'>
                  {user.email}
                </p>
              </div>
            </td>
            <td>
              <div className='d-flex flex-column gap-2'>
                <p className='mb-0 font-size14 line-height font-normal'>
                  <div className='d-flex align-items-center gap-1'>
                    <Star1 size='16' color='#facc15' variant='Bold' />
                    <span>{user.rating}</span>
                  </div>
                </p>
              </div>
            </td>
            <td>
              <div className='d-flex flex-column gap-2'>
                <p className='mb-0 font-size14 line-height font-normal'>
                  ${user.totalEarnings}
                </p>
              </div>
            </td>
            <td>
              <div className='d-flex flex-column gap-2'>
                <p className='mb-0 font-size14 line-height font-normal'>
                  ${user.pendingPayments}
                </p>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <>
      <Header Display='none' href='' headerTitle='Admin Panel' />
      <div className='container-fluid px-0'>
        <div className='row '>
          <div className='col-12 col-md-6 dashboard-search mb-48'>
            <div className='search-input d-flex align-items-center '>
              <input
                type='text'
                className='form-control'
                onChange={(e) => setSearchText(e.target.value)}
                placeholder='Search something...'
              />
              <div className='d-flex align-items-center all-business-search advance-search gap-3  flex-grow-1'>
                <SearchNormal1 size='20' color='#020617' />
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 add-productbtn-col text-end'>
            <button
              data-bs-toggle='modal'
              data-bs-target='#ClearPaymentModal'
              className='btn'
            >
              Clear Payments
            </button>
          </div>

          <div className='col-12 all-orders-col collaborator-col'>
            <div className='all-orders bg-white radius-16 overflow-hidden'>
              <div className='setting-col-header p-24 '>
                <p className='line-height mb-0'>Admins</p>
              </div>
              <table className='table border-t-0'>
                <thead>
                  <tr>
                    <th className='font-normal'>First Name</th>
                    <th className='font-normal'>Last Name</th>
                    <th className='font-normal'>Email Address</th>
                    <th className='font-normal'>Rating</th>
                    <th className='font-normal'>Total Earnings</th>
                    <th className='font-normal'>Pending Payments</th>
                  </tr>
                </thead>
                {filteredUsers.length > 0 && (
                  <Pagination
                    renderComponent={renderUserItems}
                    items={filteredUsers.filter(
                      (user) => user.role === UserRolesEnum.ADMIN
                    )}
                  />
                )}
              </table>

              <div className='setting-col-header p-24 '>
                <p className='line-height mb-0'>Job Posters</p>
              </div>
              <table className='table border-t-0'>
                <thead>
                  <tr>
                    <th className='font-normal'>First Name</th>
                    <th className='font-normal'>Last Name</th>
                    <th className='font-normal'>Email Address</th>
                    <th className='font-normal'>Rating</th>
                    <th className='font-normal'>Total Earnings</th>
                    <th className='font-normal'>Pending Payments</th>
                  </tr>
                </thead>
                {filteredUsers.length > 0 && (
                  <Pagination
                    renderComponent={renderUserItems}
                    items={filteredUsers.filter(
                      (user) => user.role === UserRolesEnum.RECRUITER
                    )}
                  />
                )}
              </table>

              <div className='setting-col-header p-24 '>
                <p className='line-height mb-0'>Freelancers</p>
              </div>
              <table className='table border-t-0'>
                <thead>
                  <tr>
                    <th className='font-normal'>First Name</th>
                    <th className='font-normal'>Last Name</th>
                    <th className='font-normal'>Email Address</th>
                    <th className='font-normal'>Rating</th>
                    <th className='font-normal'>Total Earnings</th>
                    <th className='font-normal'>Pending Payments</th>
                  </tr>
                </thead>
                {filteredUsers.length > 0 && (
                  <Pagination
                    renderComponent={renderUserItems}
                    items={filteredUsers.filter(
                      (user) => user.role === UserRolesEnum.FREELANCER
                    )}
                  />
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      <ClearPaymentModal
        freelancers={freelancers}
        onPaymentClear={handleClearPayment}
      />
    </>
  );
}
