import React, { useState } from 'react';
import toast from 'react-hot-toast';

export default function ClearPaymentModal({ freelancers, onPaymentClear }) {
  const [selectedFreelancer, setSelectedFreelancer] = useState(null);
  const [amount, setAmount] = useState('');

  const handleFreelancerChange = (e) => {
    const freelancerId = e.target.value;
    const freelancer = freelancers.find(
      (user) => user.id === parseInt(freelancerId)
    );
    setSelectedFreelancer(freelancer);
  };

  const handleClearPayment = () => {
    if (!selectedFreelancer) {
      toast.error('Please select a freelancer.');
      return;
    }

    if (
      parseFloat(amount) <= 0 ||
      parseFloat(amount) > parseFloat(selectedFreelancer.pendingPayments)
    ) {
      toast.error('Please enter a valid amount.');
      return;
    }

    onPaymentClear(selectedFreelancer.id, parseFloat(amount));
  };

  return (
    <>
      <div className='modal place-order-modal' id='ClearPaymentModal'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content '>
            <div className='modal-header flex-column gap-2 align-items-center justify-content-center  p-32 slate-50'>
              <h2 className='modal-title line-height text-center '>
                Clear Pending Payment
              </h2>
              <p className='mb-0 text-center slate-500 font-normal line-height-200'>
                Select a freelancer and enter the amount to clear the pending
                payment.
              </p>
            </div>
            <div className='modal-body advance-search-modal position-relative p-0 text-center'>
              <div className='auth-form d-flex flex-column gap-32 p-32 '>
                <div className='gap-3 d-flex flex-column align-items-start'>
                  <label className='mb-2'>Select Freelancer</label>
                  <select
                    required
                    className='form-select form-control px-4'
                    onChange={handleFreelancerChange}
                    defaultValue=''
                  >
                    <option selected disabled value=''>
                      Choose a freelancer
                    </option>
                    {freelancers.map((freelancer) => (
                      <option key={freelancer.id} value={freelancer.id}>
                        {freelancer.firstName} {freelancer.lastName} -{' '}
                        {freelancer.email}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedFreelancer && (
                  <>
                    <div className='gap-3 d-flex flex-column align-items-start'>
                      <label className='mb-2'>Pending Payment</label>
                      <div className='d-flex align-items-center w-100 gap-3'>
                        <input
                          type='text'
                          className='form-control flex-grow-1 '
                          value={`$${selectedFreelancer.pendingPayments}`}
                          disabled
                        />
                      </div>
                    </div>

                    <div className='gap-3 d-flex flex-column align-items-start'>
                      <label className='mb-2'>Amount to Clear</label>
                      <div className='d-flex align-items-center w-100 gap-3'>
                        <input
                          type='number'
                          className='form-control flex-grow-1 '
                          placeholder='Enter amount'
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='d-flex align-items-center border-0 pt-3 modal-footer confirm-modal-footer gap-3 p-32 justify-content-between '>
              <button
                type='button'
                className='btn button2'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>

              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={handleClearPayment}
              >
                Clear Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
