import { Star1 } from 'iconsax-react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function BrowseJobCard(props) {
  return (
    <>
      <div className='col-12 col-md-4 col-lg-4 col-xxl-3 mb-4  product-card'>
        <Link to={props.href}>
          <div className='bg-white product-card-details overflow-hidden position-relative radius-16'>
            <div className='card-main-section flex-column d-flex gap-3 p-3'>
              <p className='font-normal mb-0'> {props.jobTitle}</p>
              <img
                className='w-100 h-100'
                src={props.image}
                alt='product-img'
              />
              <ul className='d-flex badge-list align-items-center gap-2'>
                <li className='slate-500 category-title'>
                  {props.difficultyLevel}
                </li>
                <li className='slate-500 category-title'>{props.radius} Mi</li>
              </ul>
              <div className='product-name d-flex flex-column gap-2'>
                <p className='font-size14 mb-0'> {props.userName}</p>
                <div className='d-flex align-items-center justify-content-between'>
                  <h5 className='mb-0'>${props.price}</h5>
                  <span className='d-flex gap-2 rating-span align-items-center font-normal'>
                    <Star1 size='16' color='#facc15' variant='Bold' />
                    {props.rating}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
