import { SearchNormal1 } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { apiHost } from '../../constants';
import jobService from '../../services/job-service';

export default function CustomOffers({ recruiter, setSelectedJob }) {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [locationError, setLocationError] = useState(null);

  useEffect(() => {
    const getUserLocation = () => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            fetchJobs(position.coords.latitude, position.coords.longitude);
          },
          (error) => {
            setLocationError(
              'Location access denied. Please enable location services.'
            );
            setLoading(false);
          }
        );
      } else {
        setLocationError('Geolocation is not supported by this browser.');
        setLoading(false);
      }
    };

    const fetchJobs = async (latitude, longitude) => {
      try {
        const queryParams = `latitude=${latitude}&longitude=${longitude}`;
        const response = await jobService.searchJobsByRecruiterId(
          recruiter.id,
          queryParams
        );

        if (response?.error) {
          setJobs([]);
          if (!response?.error.includes('not found'))
            toast.error(response?.error);
        } else {
          setJobs(response);
        }
      } catch (error) {
        toast.error('Failed to fetch jobs');
      } finally {
        setLoading(false);
      }
    };

    if (recruiter && recruiter.id) getUserLocation();
  }, [recruiter]);

  const filteredJobs = jobs?.filter((job) =>
    job.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className='modal custom-offers-modal' id='CustomOffers'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content overflow-hidden '>
            <div className='modal-header flex-column  slate-50 p-32 text-center'>
              <h2 className='modal-title mx-auto'>Custom Offer</h2>
              <p className='slate-500 font-normal line-height-200 mb-0'>
                Choose one of the job from your listing below to send this user
                a custom offer tailored to their requirements.
              </p>
              <div className='auth-form d-flex flex-column gap-32 px-0 pb-0 p-32 w-100'>
                <div className='d-flex flex-column gap-3'>
                  <div className='d-flex align-items-start location-input flex-grow-1 flex-column gap-4 position-relative'>
                    <input
                      type='text'
                      className='form-control  '
                      placeholder='Search something...'
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <span className='position-absolute'>
                      <SearchNormal1 size='20' color='#020617' />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal-body advance-search-modal position-relative p-32 text-center'>
              {loading && <p className='text-info'>Loading jobs...</p>}
              {locationError && <p className='text-danger'>{locationError}</p>}
              {!loading && !locationError && (
                <ul className='custom-offers-ul gap-24'>
                  {filteredJobs &&
                    filteredJobs.length > 0 &&
                    filteredJobs.map((job) => (
                      <li
                        key={job.id}
                        className='product-card cursor-pointer'
                        onClick={() => setSelectedJob(job)}
                        data-bs-toggle='modal'
                        data-bs-target='#CustomOffersQuantity'
                      >
                        <div className='bg-white product-card-details overflow-hidden position-relative radius-16'>
                          <div className={`band d-none`}>Sponsored</div>
                          <div className='card-main-section flex-column d-flex gap-3 p-3'>
                            <img
                              className='w-100'
                              src={
                                `${apiHost}/${job?.JobImages[0]?.url}` ||
                                '../../assets/images/dummy-img2.png'
                              }
                              alt='product-img'
                            />
                            <ul className='d-flex badge-list align-items-center gap-2'>
                              <li className='slate-500'>
                                {job.difficultyLevel}
                              </li>
                              <li className='slate-500'>{job.radius} Mi</li>
                            </ul>
                            <div className='product-name d-flex flex-column gap-2'>
                              <p className='font-size14 mb-0 text-start'>
                                {job.title}
                              </p>
                              <div className='d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0'>${job.price}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              )}
              {!loading && !locationError && filteredJobs.length === 0 && (
                <p className='alert alert-warning'>No jobs found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
