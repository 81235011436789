import { SearchNormal1 } from 'iconsax-react';
import React, { useState, useEffect, useMemo } from 'react';
import Header from '../../layout/header';
import Pagination from '../../components/Pagination';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { apiHost, UserRolesEnum } from '../../constants';
import jobService from '../../services/job-service';
import BrowseJobCard from '../../components/BrowseJobCard';

export default function MyJobs(props) {
  const [userRole, setUserRole] = useState('');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const user = localStorage.getItem('user');
    const parsedUser = JSON.parse(user);
    setUserRole(parsedUser.role);
  }, []);

  useEffect(() => {
    const fetchMyJobs = async () => {
      try {
        const response = await jobService.getMyJobs();
        if (response?.error) {
          if (!response?.error.includes('not found'))
            toast.error(response?.error);
        } else {
          setJobs(response);
        }
      } catch (error) {
        toast.error('Failed to fetch jobs');
      } finally {
        setLoading(false);
      }
    };

    fetchMyJobs();
  }, []);

  const { filteredJobs } = useMemo(() => {
    if (!jobs) {
      return { filteredJobs: [] };
    }

    const filterJobs = (jobs) => {
      return jobs.filter((job) => {
        if (searchText) {
          return (
            job.title.toLowerCase().includes(searchText.toLowerCase()) ||
            job.description.toLowerCase().includes(searchText.toLowerCase())
          );
        }
        return true;
      });
    };

    return { filteredJobs: filterJobs(jobs) };
  }, [jobs, searchText]);

  const renderJobItems = (jobs) => {
    if (loading) {
      return <div className='text-info'>Loading jobs...</div>;
    }

    if (!loading && jobs.length === 0) {
      return <div className='alert alert-warning'>No jobs to display</div>;
    }

    return (
      <div className='row product-and-inventory-row'>
        {jobs.map((job) => (
          <BrowseJobCard
            key={job.id}
            jobTitle={job.title}
            href={`/dashboard/my-job-details/${job.id}`}
            image={`${apiHost}/${job.JobImages[0]?.url}`}
            difficultyLevel={job.difficultyLevel}
            radius={job.radius}
            userName={`${job.Recruiter?.firstName} ${job.Recruiter?.lastName}`}
            price={job.price}
            rating={job.Recruiter?.rating}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <Header Display='none' href='' headerTitle='My Jobs' />
      <div className='container-fluid px-0'>
        <div className='row mb-48'>
          <div className='col-12 col-md-6 dashboard-search'>
            <div className='search-input d-flex align-items-center '>
              <input
                type='text'
                onChange={(e) => setSearchText(e.target.value)}
                className='form-control '
                placeholder='Search something...'
              />
              <div className='d-flex align-items-center advance-search gap-3  flex-grow-1 '>
                <SearchNormal1 size='20' color='#020617' />
              </div>
            </div>
          </div>
          {userRole === UserRolesEnum.RECRUITER && (
            <div className='col-12 col-md-6 add-productbtn-col text-end'>
              <Link to='/dashboard/add-job' className='btn'>
                Add a Job
              </Link>
            </div>
          )}
        </div>

        <Pagination
          isTable={false}
          renderComponent={renderJobItems}
          items={filteredJobs}
        />
      </div>
    </>
  );
}
