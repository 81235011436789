import { Trash } from 'iconsax-react';

export default function DeleteJob(props) {
  const handleSubmit = (event) => {
    props.onSave();
  };

  return (
    <>
      <div className='modal promote-product' id='DeleteProduct'>
        <div
          className='modal-dialog modal-dialog-centered '
          style={{ maxWidth: '385px' }}
        >
          <div className='modal-content radius- overflow-hidden '>
            <div className='modal-header flex-column gap-2 align-items-center justify-content-center  p-24 slate-50'>
              <Trash size='64' color='#7c3aed' variant='Bulk' />
              <h2 className='modal-title line-height text-center '>
                Delete Job
              </h2>
              <p className='mb-0 text-center slate-500 font-normal line-height-200'>
                Are you sure you wish to proceed?
              </p>
            </div>

            <div className='d-flex align-items-center modal-footer confirm-modal-footer gap-3 p-24 justify-content-between '>
              <button
                type='button'
                className='btn button2'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>

              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={handleSubmit}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
