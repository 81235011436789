import React, { useEffect } from 'react';
import Header from '../../layout/header';
import { Trash } from 'iconsax-react';
import Slider from 'react-slick';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { apiHost } from '../../constants';
import jobService from '../../services/job-service';
import DeleteJob from '../../components/MyJobs/DeleteJob';

export default function MyJobDetails(props) {
  const navigate = useNavigate();
  const { id } = useParams();

  const [job, setJob] = useState([]);
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: false,
    infinite: true,
    prevArrow: false,
    nextArrow: false,
    speed: 500,
    slidesToShow: Math.min(2, job?.JobImages?.length),
    slidesToScroll: 1,
  };

  useEffect(() => {
    const fetchJobById = async () => {
      try {
        const response = await jobService.getJobById(id);

        if (response?.error) {
          if (!response?.error.includes('not found'))
            toast.error(response?.error);
        } else {
          setJob(response);
        }
      } catch (error) {
        toast.error('Failed to fetch job');
      } finally {
        setLoading(false);
      }
    };

    fetchJobById();
  }, [id]);

  const deleteJob = async () => {
    try {
      const response = await jobService.deleteMyJob(job.id);

      if (response?.error) {
        toast.error(response?.error);
      } else {
        toast.success(response?.message);
        navigate('/dashboard/my-jobs');
      }
    } catch (error) {
      toast.error('Failed to delete job');
    }
  };

  return (
    <>
      <Header
        Display='block'
        href='/dashboard/my-jobs'
        headerTitle='Job Details'
      />
      {loading ? (
        <div>Loading job details...</div>
      ) : job ? (
        <>
          <div className='container-fluid px-0'>
            <div className='row '>
              <div className='col-12 col-lg-9 product-details'>
                <div className='product-details-col bg-white d-flex flex-column p-24 radius-16 gap-32'>
                  <div className='d-flex product-name-details align-items-center justify-content-between'>
                    <div className='d-flex flex-column gap-2'>
                      <p className='font-size14 line-height mb-0'>
                        {job.title}
                      </p>
                      <h4 className='mb-0 line-height'>${job.price}</h4>
                    </div>

                    <div className='edit-product-btn-col d-flex align-items-center gap-4'>
                      <ul className='d-flex badge-list align-items-center gap-2'>
                        <li className='slate-500'>{job.difficultyLevel}</li>
                        <li className='slate-500'>{job.radius} Mi</li>
                      </ul>

                      <ul className='edit-delete-btn d-flex align-items-center gap-3 ps-4'>
                        <li>
                          <Trash
                            className='cursor-pointer'
                            data-bs-toggle='modal'
                            data-bs-target='#DeleteProduct'
                            color='#64748b'
                            variant='Outline'
                          />
                        </li>
                        <li>
                          <Link
                            to={`/dashboard/edit-job/${job.id}`}
                            className='btn button2 font-size14 line-height'
                          >
                            Edit Job
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className='product-details-img d-flex align-items-center gap-4'>
                    <Slider className='h-100 w-100 ' {...settings}>
                      {job.JobImages.map((img, index) => (
                        <div key={index}>
                          <img
                            className='img-fluid'
                            src={`${apiHost}/${img.url}`}
                            alt='job-details-img'
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>

                  <div className='d-flex flex-column gap-3'>
                    <p className='mb-0 slate-500 font-size14 font-normal line-height-200'>
                      {job.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='alert alert-warning'>Job not found</div>
      )}
      <DeleteJob job={job} onSave={deleteJob} />
    </>
  );
}
