import toast from 'react-hot-toast';
import { apiHost } from '../../constants';
import jobOfferService from '../../services/job-offer-service';

export default function CustomOfferMessage({ job, jobOffer, isSender }) {
  const handlePayOffer = async () => {
    try {
      const response = await jobOfferService.payJobOffer(jobOffer.id);

      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.success('You will be redirected for payment shortly.');
        window.location.href = response.stripeUrl;
      }
    } catch (error) {
      toast.error('Failed to process payment');
    }
  };

  return (
    <div
      style={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '350px',
        margin: 'auto',
      }}
    >
      <img
        src={`${apiHost}/${job?.JobImages[0]?.url}`}
        alt='Job'
        style={{
          width: '120px',
          height: '120px',
          objectFit: 'cover',
          borderRadius: '8px',
          marginBottom: '15px',
        }}
      />
      <p
        style={{
          margin: '10px 0',
          color: '#333',
          fontWeight: 'bold',
          fontSize: '18px',
          textAlign: 'center',
        }}
      >
        {job?.title}
      </p>
      <p
        style={{
          margin: '10px 0',
          color: '#FF5722',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      >
        Custom Price: ${jobOffer?.offeredPrice}
      </p>
      <div
        style={{
          display: 'flex',
          marginTop: '20px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {job?.status === 'closed' && jobOffer.isAccepted !== 1 && (
          <button
            disabled={true}
            style={{
              backgroundColor: '#E0E0E0',
              border: 'none',
              color: 'black',
              padding: '10px 20px',
              textAlign: 'center',
              textDecoration: 'none',
              fontSize: '16px',
              borderRadius: '4px',
              cursor: 'not-allowed',
              opacity: '0.75',
              flex: 1,
            }}
          >
            Closed
          </button>
        )}

        {jobOffer.isAccepted === 0 && isSender && job?.status !== 'closed' && (
          <button
            disabled={true}
            style={{
              backgroundColor: '#B0BEC5',
              border: 'none',
              color: 'white',
              padding: '10px 20px',
              textAlign: 'center',
              textDecoration: 'none',
              fontSize: '16px',
              borderRadius: '4px',
              cursor: 'not-allowed',
              flex: 1,
            }}
          >
            Pending Approval
          </button>
        )}

        {jobOffer.isAccepted === 0 && !isSender && job?.status !== 'closed' && (
          <button
            onClick={handlePayOffer}
            style={{
              backgroundColor: '#4CAF50',
              border: 'none',
              color: 'white',
              padding: '10px 20px',
              textAlign: 'center',
              textDecoration: 'none',
              fontSize: '16px',
              borderRadius: '4px',
              cursor: 'pointer',
              flex: 1,
              marginRight: '10px',
            }}
          >
            Accept
          </button>
        )}

        {jobOffer.isAccepted === 1 && (
          <button
            disabled={true}
            style={{
              backgroundColor: '#4CAF50',
              border: 'none',
              color: 'white',
              padding: '10px 20px',
              textAlign: 'center',
              textDecoration: 'none',
              fontSize: '16px',
              borderRadius: '4px',
              cursor: 'not-allowed',
              opacity: '0.75',
              flex: 1,
            }}
          >
            Accepted
          </button>
        )}
      </div>
    </div>
  );
}
