import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiHost } from '../constants';
import 'react-datepicker/dist/react-datepicker.css';
import jobOfferService from '../services/job-offer-service';
import toast from 'react-hot-toast';

export default function AcceptJob({ user, job }) {
  const navigate = useNavigate();

  const handleAcceptJob = async () => {
    if (!user) {
      document.querySelector('#AcceptJob .btn-close').click();
      return navigate('/');
    }

    try {
      const response = await jobOfferService.createJobOffer(job.id, {
        offeredPrice: job.price,
      });

      if (response?.error) {
        toast.error(response.error);
      } else {
        document.querySelector('#AcceptJob .btn-close').click();
        const successModal = new window.bootstrap.Modal(
          document.getElementById('JobAcceptedSuccessfully')
        );
        successModal.show();
      }
    } catch (error) {
      toast.error('Failed to accept job offer');
    }
  };

  return (
    <>
      <div className='modal place-order-modal' id='AcceptJob'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content radius-24'>
            <div className='modal-header p-32'>
              <h5 className='modal-title line-height'>Accept Job</h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body advance-search-modal position-relative p-0 text-center'>
              <div className='auth-form d-flex flex-column gap-32 p-32 '>
                <div className='product-detail-modal text-start overflow-hidden '>
                  <ul className='d-flex align-items-center gap-3  '>
                    <li>
                      <img
                        className='rounded-circle'
                        src={`${apiHost}/${job?.JobImages[0]?.url}`}
                        height='48px'
                        width='48px'
                        alt=''
                      />
                    </li>
                    <li className='text-start d-flex flex-column gap-2'>
                      <p className='mb-0 font-size14 line-height'>
                        {job.title}
                      </p>
                      <span
                        className='slate-500 line-height d-flex align-items-center gap-2'
                        style={{ fontSize: '12px' }}
                      >
                        {`$${job.price}`}
                      </span>
                    </li>
                  </ul>
                  <p className='font-size14 slate-50 mb-0 font-normal'>
                    <span
                      className='slate-500 me-3 pe-3 font-normal'
                      style={{ borderRight: '1px solid #E2E8F0' }}
                    >
                      Price
                    </span>{' '}
                    ${job.price}
                  </p>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center modal-footer p-32 justify-content-between '>
              <button
                type='button'
                className='btn button2'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>

              <button
                type='button'
                className='btn btn-danger'
                onClick={handleAcceptJob}
              >
                Accept Job
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
