import React, { useState } from 'react';
import styled from 'styled-components';
import { MessageQuestion } from 'iconsax-react';
import toast from 'react-hot-toast';
import emailService from '../services/email-service';

const Button = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #114a65;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Modal = styled.div`
  position: fixed;
  bottom: 90px;
  right: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
  width: 300px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SendButton = styled.button`
  background-color: #38b1ed;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
`;

const SupportButton = () => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSend = async () => {
    try {
      const response = await emailService.supportRequest({
        name,
        email,
        message,
      });
      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.success(response?.message);
        setName('');
        setEmail('');
        setMessage('');
        setShowModal(false);
      }
    } catch (error) {
      toast.error('Failed to send support request');
    }
  };

  return (
    <>
      <Button onClick={() => setShowModal(!showModal)}>
        <MessageQuestion size='24' color='white' variant='Outline' />
      </Button>
      {showModal && (
        <Modal>
          <h3>Chat With Support</h3>
          <Input
            type='text'
            placeholder='Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextArea
            rows='4'
            placeholder='Message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <SendButton onClick={handleSend}>Send</SendButton>
        </Modal>
      )}
    </>
  );
};

export default SupportButton;
