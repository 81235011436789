import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { apiHost } from '../../constants';
import Header from '../../layout/header';
import { Star1 } from 'iconsax-react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import jobService from '../../services/job-service';
import AcceptJob from '../../components/AcceptJobModal';
import JobAcceptedSuccessfully from '../../components/BrowseJobs/JobAcceptedSuccessfully';

export default function JobDetails(props) {
  const { id } = useParams();

  const [user, setUser] = useState(null);
  const [job, setJob] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      setUser(parsedUser);
    }
  }, []);

  useEffect(() => {
    const fetchJobById = async () => {
      try {
        const response = await jobService.getJobById(id);

        if (response?.error) {
          if (!response?.error.includes('not found'))
            toast.error(response?.error);
        } else {
          setJob(response);
        }
      } catch (error) {
        toast.error('Failed to fetch job');
      } finally {
        setLoading(false);
      }
    };

    fetchJobById();
  }, [id]);

  const settings = {
    dots: false,
    infinite: true,
    prevArrow: false,
    nextArrow: false,
    speed: 500,
    slidesToShow: Math.min(2, job?.JobImages?.length),
    slidesToScroll: 1,
  };

  $(function () {
    $('.Negotiate-btn-click').click(function () {
      $('.message-handle').addClass('active').siblings().removeClass('active');
    });
  });

  return (
    <>
      <Header Display='block' href='/dashboard' headerTitle='Job Details' />
      {loading ? (
        <div>Loading job details...</div>
      ) : job ? (
        <>
          <div className='container-fluid px-0'>
            <div className='row '>
              <div className='col-12 col-lg-9 product-details'>
                <div className='product-details-col bg-white d-flex flex-column p-24 radius-16 gap-32'>
                  <div className='d-flex product-details-Negotiate align-items-center justify-content-between'>
                    <ul className='d-flex align-items-center gap-3'>
                      <li>
                        <img
                          style={{ border: '1px solid #7C3AED' }}
                          className='rounded-circle p-1 img-fluid'
                          height={'48'}
                          width={'48'}
                          src='../../assets/images/dummy-img.png'
                          alt='user-profile-img'
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = '../../assets/images/dummy-img.png';
                          }}
                        />
                      </li>
                      <li className='d-flex flex-column gap-1'>
                        <p className='font-size14 line-height mb-0 d-flex align-items-center gap-2'>
                          {job.Recruiter.firstName} {job.Recruiter.lastName}
                        </p>
                        <ul
                          className='d-flex align-items-end'
                          style={{ gap: '2px;' }}
                        >
                          {[...Array(5)].map((star, index) => {
                            const ratingValue = index + 1;
                            return (
                              <li key={index} className='line-height'>
                                <Star1
                                  size='16'
                                  color={
                                    ratingValue <= job.Recruiter.rating
                                      ? '#facc15'
                                      : '#e4e5e9'
                                  }
                                  variant='Bold'
                                />
                              </li>
                            );
                          })}
                          <li className='line-height font-size14 font-normal ms-2'>
                            {job.Recruiter.rating}
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <Link
                      to={
                        user
                          ? `/dashboard/messages?recruiterId=${job.Recruiter.id}`
                          : `/`
                      }
                    >
                      <button className='btn Negotiate-btn-click button2 font-size14 line-height'>
                        Negotiate a Price
                      </button>
                    </Link>
                  </div>

                  <div className='d-flex product-name-details align-items-start justify-content-between'>
                    <div className='d-flex flex-column gap-2'>
                      <p className='font-size14 line-height mb-0'>
                        {job.title}
                      </p>
                      <h4 className='mb-0 line-height'>{`$${job.price}`}</h4>
                    </div>
                    <ul className='d-flex badge-list align-items-center gap-2'>
                      <li className='slate-500'>{job.difficultyLevel}</li>
                      <li className='slate-500'>{job.radius} Mi</li>
                    </ul>
                  </div>

                  <div className='product-details-img d-flex align-items-center gap-4'>
                    <Slider className='h-100 w-100' {...settings}>
                      {job?.JobImages?.map((img, index) => (
                        <div key={index}>
                          <img
                            className='img-fluid'
                            src={`${apiHost}/${img?.url}`}
                            alt='product-details-img'
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>

                  <div className='d-flex flex-column gap-3'>
                    <p className='mb-0 slate-500 font-size14 font-normal line-height-200'>
                      {job.description}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-3 d-flex flex-column justify-content-end  product-details-map'>
                <button
                  className='btn order-btn ms-auto line-height'
                  data-bs-toggle='modal'
                  data-bs-target='#AcceptJob'
                >
                  Accept Now
                </button>
              </div>
            </div>
          </div>

          <AcceptJob user={user} job={job} />
          <JobAcceptedSuccessfully />
        </>
      ) : (
        <div className='alert alert-warning'>Job not found</div>
      )}
    </>
  );
}
