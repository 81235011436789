import axios from './axios';

const login = async (email, password) => {
  try {
    const response = await axios.post(`/auth`, {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAuthStatus = () => {
  const accessToken = localStorage.getItem('access-token');
  const refreshToken = localStorage.getItem('refresh-token');
  const user = localStorage.getItem('user');

  if (accessToken && refreshToken && user) {
    return true;
  } else {
    return false;
  }
};

const updatePassword = async (data) => {
  try {
    const response = await axios.patch(`/auth/updatePassword`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const logout = async () => {
  try {
    const response = await axios.post(`/auth/logout`, {});

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const authService = {
  login,
  getAuthStatus,
  updatePassword,
  logout,
};

export default authService;
