import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import authService from '../../services/auth-service';
import {
  Box,
  HambergerMenu,
  Menu,
  Message2,
  Notification,
  Profile2User,
  Setting,
  SliderHorizontal,
} from 'iconsax-react';
import useIsMobile from '../../Hooks/useIsMobile';
import { UserRolesEnum } from '../../constants';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

export default function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const [user, setUser] = useState(null);
  const [active, setActive] = useState('');
  const [show, setShow] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  useEffect(() => {
    if (isMobile) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [isMobile]);

  useEffect(() => {
    const currentPath = location.pathname.split('/')[2];

    if (!currentPath) setActive('dashboard');
    else setActive(currentPath);
  }, [location.pathname]);

  useEffect(() => {
    const user = localStorage.getItem('user');

    if (user) {
      const parsedUser = JSON.parse(user);
      setUser(parsedUser);

      const q = query(
        collection(db, 'chats'),
        where(`unreadMessages.${parsedUser.id}`, '==', true)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        setUnreadMessageCount(querySnapshot.size);
      });

      return () => unsubscribe();
    }
  }, []);

  const navActive = (name) => {
    if (isMobile) {
      setShow(false);
    }
    setActive(name);
  };

  const sidebarHandler = () => {
    setShow(!show);
  };

  const logout = async () => {
    const response = await authService.logout();

    if (response?.error) {
      toast.error(response.error);
    } else {
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('user');

      toast.success(response?.message);
      navigate('/');
    }
  };

  return (
    <>
      <div className='d-flex d-xl-none mbl-header bg-white p-3 align-items-center justify-content-between'>
        <HambergerMenu
          className='cursor-pointer'
          onClick={sidebarHandler}
          size='36'
          color='#7c3aed'
          variant='Bulk'
        />
      </div>
      {show && (
        <div className='sidebar'>
          <div className='sidebar-content'>
            <Link
              className='d-flex mb-24'
              to={
                user && user.role === UserRolesEnum.ADMIN
                  ? '/dashboard/admin-panel'
                  : user && user.role === UserRolesEnum.RECRUITER
                  ? '/dashboard/my-jobs'
                  : '/dashboard'
              }
              onClick={() =>
                user && user.role === UserRolesEnum.ADMIN
                  ? navActive('collaborators')
                  : user && user.role === UserRolesEnum.RECRUITER
                  ? navActive('myJobs')
                  : navActive('dashboard')
              }
            >
              <img
                className='img-fluid'
                style={{ width: '100px' }}
                src='../../assets/images/logo.png'
                alt='login-logo'
              />
            </Link>

            <ul className='d-flex flex-column gap-3 mb-5 '>
              {(!user || user.role === UserRolesEnum.FREELANCER) && (
                <li
                  onClick={() => navActive('dashboard')}
                  className={`${active === 'dashboard' ? 'active' : ''}`}
                >
                  <Link
                    to={'/dashboard'}
                    className='d-flex align-items-center gap-3 text-white '
                  >
                    <SliderHorizontal
                      size='20'
                      color='#ffffff'
                      variant='Outline'
                    />
                    Browse Jobs
                  </Link>
                </li>
              )}

              {user && user.role !== UserRolesEnum.ADMIN && (
                <li
                  onClick={() => navActive('my-jobs')}
                  className={`${active === 'my-jobs' ? 'active' : ''}`}
                >
                  <Link
                    to={'/dashboard/my-jobs'}
                    className='d-flex align-items-center gap-3 text-white '
                  >
                    <Menu size='20' color='#ffffff' variant='Outline' />
                    My Jobs
                  </Link>
                </li>
              )}

              {user && user.role === UserRolesEnum.ADMIN && (
                <li
                  onClick={() => navActive('admin-panel')}
                  className={`${active === 'admin-panel' ? 'active' : ''}`}
                >
                  <Link
                    to={'/dashboard/admin-panel'}
                    className='d-flex align-items-center gap-3 text-white '
                  >
                    <Profile2User size='16' color='#ffffff' variant='Outline' />
                    Admin Panel
                  </Link>
                </li>
              )}

              {user && user.role !== UserRolesEnum.ADMIN && (
                <>
                  <li
                    onClick={() => navActive('accepted-jobs')}
                    className={`accepted-jobs ${
                      active === 'accepted-jobs' ? 'active' : ''
                    }`}
                  >
                    <Link
                      to={'/dashboard/accepted-jobs'}
                      className='d-flex align-items-center gap-3 text-white '
                    >
                      <Box size='20' color='#ffffff' variant='Outline' />
                      Accepted Jobs
                    </Link>
                  </li>

                  <li
                    onClick={() => {
                      navActive('messages');
                    }}
                    className={`message-handle ${
                      active === 'messages' ? 'active' : ''
                    }`}
                  >
                    <Link
                      to={'/dashboard/messages'}
                      className='d-flex align-items-center gap-3 text-white '
                    >
                      <Message2 size='20' color='#ffffff' variant='Outline' />
                      Messages
                      <span className='yellow-badge ms-auto'>
                        {unreadMessageCount}
                      </span>
                    </Link>
                  </li>
                </>
              )}

              {user && user.role === UserRolesEnum.RECRUITER && (
                <li
                  onClick={() => {
                    navActive('job-offers');
                  }}
                  className={`${active === 'job-offers' ? 'active' : ''}`}
                >
                  <Link
                    to={'/dashboard/job-offers'}
                    className='d-flex align-items-center gap-3 text-white '
                  >
                    <Notification size='20' color='#ffffff' variant='Outline' />
                    Job Offers
                  </Link>
                </li>
              )}

              {user && (
                <li
                  id='settings'
                  onClick={() => navActive('settings')}
                  className={`settings ${
                    active === 'settings' ? 'active' : ''
                  }`}
                >
                  <Link
                    to={'/dashboard/settings'}
                    className='d-flex align-items-center gap-3 text-white '
                  >
                    <Setting size='20' color='#ffffff' variant='Outline' />
                    Settings
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <ul>
            {user && (
              <li onClick={logout}>
                <Link className='d-flex align-items-center gap-3 text-white '>
                  <SliderHorizontal
                    size='20'
                    color='#ffffff'
                    variant='Outline'
                  />
                  Logout
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
}
