import { TickCircle } from 'iconsax-react';

export default function JobAcceptedSuccessfully(props) {
  return (
    <>
      <div className='modal promote-product' id='JobAcceptedSuccessfully'>
        <div
          className='modal-dialog modal-dialog-centered '
          style={{ maxWidth: '385px' }}
        >
          <div className='modal-content radius- overflow-hidden '>
            <div className='modal-header flex-column gap-2 align-items-center justify-content-center  p-24 slate-50'>
              <TickCircle size='64' color='#7c3aed' variant='Bulk' />
              <h4 className='modal-title line-height text-center '>
                Offer Sent Successsfully
              </h4>
              <p className='mb-0 text-center slate-500 font-normal line-height-200'>
                Congratulations! You have sent an offer for the job. Please wait
                for confirmation from the recruiter.
              </p>
            </div>

            <div className='d-flex align-items-center modal-footer confirm-modal-footer gap-3 p-24 justify-content-between '>
              <button
                type='button'
                className='btn button2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                Cancel
              </button>

              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
