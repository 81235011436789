import React, { useEffect, useState, useRef } from 'react';
import Header from '../../layout/header';
import { Gallery } from 'iconsax-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { apiHost, difficultyLevels, imageSizeLimit } from '../../constants';
import jobService from '../../services/job-service';
import heic2any from 'heic2any';

export default function EditJob(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const fileInputRef = useRef(null);

  const [job, setJob] = useState({
    title: '',
    description: '',
    price: 10.0,
    radius: '',
    difficultyLevel: '',
  });
  const [uploadedImages, setUploadedImages] = useState([]);
  const [deleteImageIds, setDeleteImageIds] = useState([]);

  useEffect(() => {
    const fetchJobById = async () => {
      try {
        const response = await jobService.getJobById(id);

        if (response?.error) {
          toast.error(response?.error);
        } else {
          setJob(response);
          setUploadedImages(
            response?.JobImages.map((image) => ({
              id: image.id,
              url: `${apiHost}/${image.url}`,
            }))
          );
        }
      } catch (error) {
        toast.error('Failed to fetch job');
      }
    };

    fetchJobById();
  }, [id]);

  const handlePriceChange = (e) => {
    const value = e.target.value
      .replace(/[^\d.]/g, '')
      .replace(/^(\d*\.?\d{0,2}).*$/, '$1');
    setJob({ ...job, price: value });
  };

  const handleImageUpload = async (files) => {
    const validFileTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/webp',
      'image/heic',
      'image/heif',
    ];
    const allowedFileTypesMessage =
      'Only .jpg, .jpeg, .png, .gif, .bmp, .webp, .heic, and .heif files are allowed!';
    const maxFileSize = imageSizeLimit * 1024 * 1024;

    const images = [];
    for (const file of files) {
      if (!validFileTypes.includes(file.type)) {
        toast.error(`File type not allowed. ${allowedFileTypesMessage}`);
        continue;
      } else if (file.size > maxFileSize) {
        toast.error(`File size should not exceed 10 MB.`);
        continue;
      }

      if (file.type === 'image/heic' || file.type === 'image/heif') {
        try {
          toast.loading('Converting HEIC image...');
          const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
          });
          const convertedFile = new File(
            [convertedBlob],
            file.name.replace(/\.[^/.]+$/, '.jpg'),
            { type: 'image/jpeg' }
          );
          images.push(convertedFile);
          toast.dismiss();
        } catch (error) {
          toast.error(`Error converting file: ${file.name}`);
        }
      } else {
        images.push(file);
      }
    }

    const totalUploadedImages = uploadedImages.length + images.length;
    if (totalUploadedImages <= 8) {
      setUploadedImages([...uploadedImages, ...images]);
    } else {
      toast.error('You can upload a maximum of 8 images');
    }
  };

  const openFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleImageUpload(files);
  };

  const updateJob = async () => {
    if (
      !job.title ||
      !job.description ||
      !job.price ||
      !job.radius ||
      !job.difficultyLevel
    ) {
      return toast.error('Please fill out all required fields');
    }

    if (parseFloat(job.price) < 10) {
      return toast.error('Price must be at least $10');
    }

    if (uploadedImages.length === 0) {
      return toast.error('Please upload at least one image');
    }

    const formData = new FormData();
    formData.append('title', job.title);
    formData.append('description', job.description);
    formData.append('price', job.price);
    formData.append('radius', job.radius);
    formData.append('difficultyLevel', job.difficultyLevel);

    const newImages = uploadedImages.filter((image) => !image.id);
    newImages.forEach((file) => {
      formData.append(`images`, file);
    });

    formData.append('deleteImageIds', deleteImageIds);

    try {
      const response = await jobService.updateMyJob(id, formData);
      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.success(response?.message);
        navigate('/dashboard/my-jobs');
      }
    } catch (error) {
      toast.error('Failed to update job');
    }
  };

  return (
    <>
      <Header
        Display='block'
        href={`/dashboard/my-job-details/${id}`}
        headerTitle='Edit Job'
      />
      <div className='container-fluid px-0'>
        <div className='row '>
          <div className='col-12 col-xl-6 add-product-form'>
            <div className='add-product-form bg-white p-4 accordion radius-12'>
              <div className='auth-form d-flex flex-column gap-32'>
                <select
                  className='form-select form-control px-4'
                  onChange={(e) =>
                    setJob({ ...job, difficultyLevel: e.target.value })
                  }
                  value={job.difficultyLevel}
                >
                  <option selected disabled value=''>
                    Difficulty Level
                  </option>
                  {difficultyLevels.map((difficultyLevel) => (
                    <option key={difficultyLevel} value={difficultyLevel}>
                      {difficultyLevel}
                    </option>
                  ))}
                </select>

                <div className='gap-4 d-flex flex-column align-items-start'>
                  <label className=''>Job Title</label>
                  <input
                    type='text'
                    required
                    className='form-control flex-grow-1 px-4'
                    placeholder='Title of Job'
                    value={job.title}
                    onChange={(e) =>
                      setJob({
                        ...job,
                        title: e.target.value,
                      })
                    }
                  />
                </div>

                <div className='d-flex align-items-start location-input flex-grow-1 flex-column gap-3 position-relative'>
                  <label className=''>Job Description</label>
                  <textarea
                    className='form-control border-0 p-1'
                    placeholder='Enter something...'
                    style={{ borderRadius: '0px' }}
                    rows={6}
                    value={job.description}
                    onChange={(e) =>
                      setJob({ ...job, description: e.target.value })
                    }
                  ></textarea>
                </div>

                <div className='gap-4 d-flex flex-column align-items-start'>
                  <label className=''>Job Radius (mi)</label>
                  <input
                    type='number'
                    required
                    className='form-control flex-grow-1 px-4'
                    placeholder='Radius'
                    value={job.radius}
                    onChange={(e) => setJob({ ...job, radius: e.target.value })}
                  />
                </div>

                <div className='gap-4 d-flex flex-column align-items-start'>
                  <label className=''>Job Price (USD)</label>
                  <input
                    type='text'
                    required
                    className='form-control flex-grow-1 px-4'
                    placeholder='Job Price'
                    value={job.price}
                    onChange={handlePriceChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-xl-6 add-product-img d-flex flex-column justify-content-between '>
            <div className='bg-white radius-12 d-flex flex-column gap-32 p-24'>
              <p className='mb-0 line-height'>Job Images</p>

              <div
                className='upload-file position-relative p-32 d-flex flex-column radius-12 align-items-center gap-3'
                onClick={openFileUpload}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{ cursor: 'pointer' }}
              >
                <Gallery size='64' color='#cbd5e1' variant='Bulk' />
                <p className='slate-500 text-center  font-size14 font-normal'>
                  Drag and drop or click to browse images from your gallery to
                  add to your product.
                </p>
                <input
                  type='file'
                  accept='.jpg,.jpeg,.png,.gif,.bmp,.webp,.heic,.heif'
                  onChange={async (e) =>
                    await handleImageUpload(e.target.files)
                  }
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  multiple
                />
              </div>

              <ul className='uploaded-product gap-3 d-grid'>
                {uploadedImages.map((file, index) => (
                  <li className='position-relative radius-12' key={index}>
                    {file.url ? (
                      <>
                        <img
                          className='img-fluid'
                          src={file.url}
                          alt={`Product Images`}
                        />
                        <span
                          className='cross-icon'
                          onClick={() => {
                            const updatedImages = [...uploadedImages];
                            const updatedDeleteImageIds = [...deleteImageIds];
                            if (updatedImages[index].id) {
                              updatedDeleteImageIds.push(
                                updatedImages[index].id
                              );
                            }
                            setDeleteImageIds(updatedDeleteImageIds);
                            updatedImages.splice(index, 1);
                            setUploadedImages(updatedImages);
                          }}
                          style={{
                            color: 'white',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                          }}
                        >
                          &#10006;
                        </span>
                      </>
                    ) : (
                      <>
                        <img
                          className='img-fluid'
                          src={URL.createObjectURL(file)}
                          alt={`Product Images`}
                        />
                        <span
                          className='cross-icon'
                          onClick={() => {
                            const updatedImages = [...uploadedImages];
                            updatedImages.splice(index, 1);
                            setUploadedImages(updatedImages);
                          }}
                          style={{
                            color: 'white',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                          }}
                        >
                          &#10006;
                        </span>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <Link
              className='btn ms-auto '
              style={{ maxWidth: '187px' }}
              onClick={updateJob}
            >
              Update Job
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
