import axios from './axios';

const createJobOffer = async (id, data) => {
  try {
    const response = await axios.post(`/jobOffer/job/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getMyJobOffers = async () => {
  try {
    const response = await axios.get(`/jobOffer/me`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getJobOffersBetweenRecruiterAndFreelancer = async (
  recruiterId,
  freelancerId
) => {
  try {
    const response = await axios.get(
      `/jobOffer/${recruiterId}/${freelancerId}`
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const payJobOffer = async (id) => {
  try {
    const response = await axios.post(`/jobOffer/pay/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const acceptJobOffer = async (id, data) => {
  try {
    const response = await axios.patch(`/jobOffer/accept/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const jobOfferService = {
  createJobOffer,
  getMyJobOffers,
  getJobOffersBetweenRecruiterAndFreelancer,
  payJobOffer,
  acceptJobOffer,
};

export default jobOfferService;
