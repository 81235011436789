import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/auth-service';
import ForgetPasswordModal from '../../components/ForgetPasswordModal';
import ResetPassword from '../../components/ResetPassword';
import { UserRolesEnum } from '../../constants';

export default function SignIn() {
  const navigate = useNavigate();

  useEffect(() => {
    const getAuthStatus = () => {
      const response = authService.getAuthStatus();

      if (response) navigate('/dashboard');
    };

    getAuthStatus();
  }, [navigate]);

  const { register, handleSubmit } = useForm({ mode: 'all' });

  const onFormSubmit = async (data) => {
    try {
      const response = await authService.login(data.email, data.password);

      if (response?.error) {
        toast.error(response?.error);
      } else {
        toast.success(response?.message);
        localStorage.setItem('user', JSON.stringify(response?.user));

        if (response?.user?.role === UserRolesEnum.ADMIN)
          navigate('/dashboard/admin-panel');
        else if (response?.user?.role === UserRolesEnum.RECRUITER)
          navigate('/dashboard/my-jobs');
        else if (response?.user?.role === UserRolesEnum.FREELANCER)
          navigate('/dashboard');
      }
    } catch (error) {
      toast.error('An error occurred during login');
    }
  };

  return (
    <>
      <div className='container-fluid auth-container'>
        <div className='row'>
          <div className='col-12 col-md-6 sign-img-col'>
            <img
              className='w-100 auth-img'
              src='../../assets/images/auth-img.jpg'
              alt='login-img'
            />
          </div>

          <div className='col-12 col-md-6 auth-col d-flex flex-column justify-content-between p-32'>
            <div className='w-100'>
              <div className='d-flex align-content-center justify-content-between mb-64'>
                <img
                  className='img-fluid'
                  style={{ width: '100px' }}
                  src='../../assets/images/logo.png'
                  alt='login-logo'
                />

                <Link to={'/signUp'} className='create-btn'>
                  Create an Account
                </Link>
              </div>

              <div className='auth-desc d-flex flex-column gap-3 mb-64'>
                <h2 className='font-normal mb-0'>Sign in to Your Account</h2>
                <p className='font-normal slate-500 mb-0'>
                  Hi there. It’s good to have you back. Please enter your email
                  address and password to sign in to your account.
                </p>
              </div>

              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className='auth-form d-flex flex-column gap-24 mb-4'>
                  <input
                    type='text'
                    id='emailAddress'
                    className='form-control'
                    placeholder='Email Address'
                    required
                    {...register('email')}
                  />
                  <input
                    type='password'
                    id='password'
                    className='form-control'
                    placeholder='Password'
                    required
                    {...register('password')}
                  />
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <button type='submit' className='btn'>
                    Get Started
                  </button>
                  <Link to={'/dashboard'} className='create-btn'>
                    Continue As Guest
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ForgetPasswordModal />
      <ResetPassword />
    </>
  );
}
