import axios from './axios';

const registerUser = async (data) => {
  try {
    const response = await axios.post('/user/register', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getMyUser = async () => {
  try {
    const response = await axios.get('/user/me');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllUsers = async () => {
  try {
    const response = await axios.get('/user');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateMyUser = async (data) => {
  try {
    const response = await axios.patch('/user/me', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const clearPendingPayment = async (id, data) => {
  try {
    const response = await axios.patch(`/user/clearPendingPayment/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const onboardUser = async () => {
  try {
    const response = await axios.get(`/user/onboard`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const withdrawMyPayments = async () => {
  try {
    const response = await axios.get(`/user/withdraw`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const userService = {
  registerUser,
  getMyUser,
  getAllUsers,
  updateMyUser,
  clearPendingPayment,
  onboardUser,
  withdrawMyPayments,
};

export default userService;
