import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Route, Routes } from 'react-router-dom';
import SideBar from './layout/Sidebar';
import BrowseJobs from './pages/BrowseJobs/index.js';
import JobDetails from './pages/JobDetails/index.js';
import MyJobs from './pages/MyJobs/index.js';
import AddJob from './pages/AddJob';
import MyJobDetails from './pages/MyJobs/MyJobDetails.js';
import EditJob from './pages/MyJobs/EditJob.js';
import JobOffers from './pages/JobOffers/index.js';
import AcceptedJobs from './pages/AcceptedJobs/index.js';
import Settings from './pages/Settings/index.js';
import AdminPanel from './pages/AdminPanel/index.js';

import Messages from './pages/Messages';

export default function dashboard(props) {
  return (
    <div className='container-fluid dashboard-container'>
      <SideBar />
      <div className='main-wrapper pb-0'>
        <Routes>
          <Route exact path='/' element={<BrowseJobs />} />
          <Route exact path='/job-details/:id' element={<JobDetails />} />
          <Route exact path='/my-jobs' element={<MyJobs />} />
          <Route exact path='/add-job' element={<AddJob />} />
          <Route exact path='/my-job-details/:id' element={<MyJobDetails />} />
          <Route exact path='/edit-job/:id' element={<EditJob />} />
          <Route exact path='/job-offers' element={<JobOffers />} />
          <Route exact path='/accepted-jobs' element={<AcceptedJobs />} />
          <Route exact path='/settings' element={<Settings />} />
          <Route exact path='/admin-panel' element={<AdminPanel />} />

          <Route exact path='/messages' element={<Messages />} />
        </Routes>
      </div>
    </div>
  );
}
