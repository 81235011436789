import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Header from '../../layout/header';
import userService from '../../services/user-service';
import authService from '../../services/auth-service';
import { Star1 } from 'iconsax-react';
import { UserRolesEnum } from '../../constants';

export default function Settings(props) {
  const [userSettings, setUserSettings] = useState({
    fullName: '',
    emailAddress: '',
  });
  const [earnings, setEarnings] = useState({
    totalEarnings: 0,
    pendingPayments: 0,
  });
  const [passwordSettings, setPasswordSettings] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await userService.getMyUser();
        if (response?.error) {
          toast.error(response.error);
        } else {
          setUserSettings({
            firstName: response.firstName,
            lastName: response.lastName,
            email: response.email,
            rating: response.rating,
            role: response.role,
            isOnboarded: response.isOnboarded,
          });
          setEarnings({
            totalEarnings: response.totalEarnings,
            pendingPayments: response.pendingPayments,
          });
        }
      } catch (error) {
        toast.error('Failed to fetch user settings');
      }
    };

    fetchSettings();
  }, []);

  const saveUserSettings = async () => {
    try {
      const formData = new FormData();
      formData.append('firstName', userSettings.firstName);
      formData.append('lastName', userSettings.lastName);
      formData.append('email', userSettings.email);

      const response = await userService.updateMyUser(formData);
      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.success(response?.message);
        localStorage.setItem('user', JSON.stringify(response?.updatedUser));
      }
    } catch (error) {
      toast.error('Failed to update user settings');
    }
  };

  const onboardUser = async () => {
    try {
      const response = await userService.onboardUser();
      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.success(response?.message);
        if (response?.accountLink) {
          window.location.href = response.accountLink;
        }
      }
    } catch (error) {
      toast.error('Failed to onboard user');
    }
  };

  const withdrawPayment = async () => {
    try {
      const response = await userService.withdrawMyPayments();
      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.success(response?.message);
        if (response?.accountLink) {
          window.location.href = response.accountLink;
        }
      }
    } catch (error) {
      toast.error('Failed to withdraw payments');
    }
  };

  const savePasswordSettings = async () => {
    const payload = { ...passwordSettings };
    delete payload['confirmPassword'];

    if (passwordSettings.newPassword === passwordSettings.confirmPassword) {
      try {
        const response = await authService.updatePassword(payload);
        if (response?.error) {
          toast.error(response.error);
        } else {
          toast.success(response?.message);
        }
      } catch (error) {
        toast.error('Failed to update password');
      }
    } else {
      toast.error('New Password and confirm password do not match');
    }
  };

  return (
    <>
      <Header Display='none' href='' headerTitle='Settings' />
      <div className='container-fluid px-0'>
        <div className='row '>
          <div className='col-12 settings-col gap-32'>
            <div className='bg-white settings-sub-col d-flex flex-column justify-content-between radius-16'>
              <div className='w-full'>
                <div className='setting-col-header p-24 '>
                  <p className='line-height mb-0'>User Settings</p>
                </div>

                <div className='auth-form d-flex flex-column gap-24 p-24'>
                  <img
                    className='rounded-circle mx-auto p-1'
                    src='../../assets/images/dummy-img.png'
                    height='120px'
                    width='120px'
                    alt=''
                    style={{ border: '1px solid #38B1ED' }}
                  />

                  <div className='d-flex align-items-start flex-grow-1 flex-column gap-3 position-relative'>
                    <label className='line-height font-size14'>
                      First Name
                    </label>
                    <input
                      type='text'
                      className='form-control font-size14'
                      placeholder='First Name'
                      value={userSettings.firstName}
                      onChange={(e) =>
                        setUserSettings({
                          ...userSettings,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className='d-flex align-items-start flex-grow-1 flex-column gap-3 position-relative'>
                    <label className='line-height font-size14'>Last Name</label>
                    <input
                      type='text'
                      className='form-control font-size14'
                      placeholder='Last Name'
                      value={userSettings.lastName}
                      onChange={(e) =>
                        setUserSettings({
                          ...userSettings,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className='d-flex align-items-start location-input flex-grow-1 flex-column gap-3 position-relative'>
                    <label className='line-height font-size14'>
                      Email Address
                    </label>
                    <input
                      type='email'
                      className='form-control font-size14'
                      placeholder='Email Address'
                      value={userSettings.email}
                      onChange={(e) =>
                        setUserSettings({
                          ...userSettings,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>

                  {userSettings.role !== UserRolesEnum.ADMIN && (
                    <div className='d-flex align-items-start location-input flex-grow-1 flex-column gap-3 position-relative'>
                      <label className='line-height font-size14'>Rating</label>
                      <ul
                        className='d-flex align-items-end'
                        style={{ gap: '2px' }}
                      >
                        {[...Array(5)].map((star, index) => {
                          const ratingValue = index + 1;
                          return (
                            <li key={index} className='line-height'>
                              <Star1
                                size='24'
                                color={
                                  ratingValue <= userSettings.rating
                                    ? '#facc15'
                                    : '#e4e5e9'
                                }
                                variant='Bold'
                              />
                            </li>
                          );
                        })}
                        <li className='line-height font-size14 font-normal ms-2'>
                          {userSettings.rating}
                        </li>
                      </ul>
                    </div>
                  )}

                  <div className='d-flex align-items-start location-input flex-grow-1 flex-column gap-3 position-relative'>
                    <label className='line-height font-size14'>Role</label>
                    <input
                      type='text'
                      className='form-control font-size14'
                      placeholder='Role'
                      value={
                        userSettings.role === UserRolesEnum.RECRUITER
                          ? 'Job Poster'
                          : userSettings.role
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className='px-4 pb-4 mt-4'>
                <button
                  className='btn w-100 '
                  type='button'
                  onClick={saveUserSettings}
                >
                  Save Changes
                </button>
              </div>
            </div>

            {userSettings.role !== UserRolesEnum.ADMIN && (
              <div className='bg-white settings-sub-col d-flex flex-column justify-content-between radius-16'>
                <div className='w-full'>
                  <div className='setting-col-header p-24 '>
                    <p className='line-height mb-0'>Earnings</p>
                  </div>

                  <div className='auth-form d-flex flex-column gap-24 p-24'>
                    <div className='d-flex align-items-start flex-grow-1 flex-column gap-3 position-relative'>
                      <label className='line-height font-size14'>
                        Total Earnings
                      </label>
                      <input
                        type='text'
                        className='form-control font-size14'
                        value={`$${earnings.totalEarnings}`}
                        disabled
                      />
                    </div>
                    <div className='d-flex align-items-start flex-grow-1 flex-column gap-3 position-relative'>
                      <label className='line-height font-size14'>
                        Pending Payments
                      </label>
                      <input
                        type='text'
                        className='form-control font-size14'
                        value={`$${earnings.pendingPayments}`}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {userSettings.role === UserRolesEnum.FREELANCER && (
                  <div className='px-4 pb-4 mt-4'>
                    {!userSettings.isOnboarded ? (
                      <button
                        className='btn w-100 '
                        type='button'
                        onClick={onboardUser}
                      >
                        Onboard with Stripe
                      </button>
                    ) : (
                      <button
                        className='btn w-100 '
                        type='button'
                        onClick={withdrawPayment}
                      >
                        Withdraw Payment
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className='bg-white settings-sub-col d-flex flex-column justify-content-between radius-16'>
              <div className='w-full'>
                <div className='setting-col-header p-24 '>
                  <p className='line-height mb-0'>Password Settings</p>
                </div>

                <div className='auth-form d-flex flex-column gap-24 p-24'>
                  <div className='d-flex align-items-start flex-grow-1 flex-column gap-3 position-relative'>
                    <label className='line-height font-size14'>
                      Old Password
                    </label>

                    <input
                      type='password'
                      className='form-control  font-size14'
                      placeholder='Old Password'
                      value={passwordSettings.oldPassword}
                      onChange={(e) =>
                        setPasswordSettings({
                          ...passwordSettings,
                          oldPassword: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className='d-flex align-items-start flex-grow-1 flex-column gap-3 position-relative'>
                    <label className='line-height font-size14'>
                      New Password
                    </label>

                    <input
                      type='password'
                      className='form-control  font-size14'
                      placeholder='New Password'
                      value={passwordSettings.newPassword}
                      onChange={(e) =>
                        setPasswordSettings({
                          ...passwordSettings,
                          newPassword: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className='d-flex align-items-start flex-grow-1 flex-column gap-3 position-relative'>
                    <label className='line-height font-size14'>
                      Confirm Password
                    </label>

                    <input
                      type='password'
                      className='form-control  font-size14'
                      placeholder='Confirm Password'
                      value={passwordSettings.confirmPassword}
                      onChange={(e) =>
                        setPasswordSettings({
                          ...passwordSettings,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='px-4 pb-4 mt-4'>
                <button
                  className='btn w-100 '
                  type='button'
                  onClick={savePasswordSettings}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
