import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAxFQtf7W4oovMPk3cIKrfbbeuLx7QjGEo",
  authDomain: "workorpost-e4701.firebaseapp.com",
  projectId: "workorpost-e4701",
  storageBucket: "workorpost-e4701.appspot.com",
  messagingSenderId: "681351064919",
  appId: "1:681351064919:web:5f6d2d7b95a205678fe212"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
