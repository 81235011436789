import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { SecuritySafe } from 'iconsax-react';
import authService from '../services/auth-service';

export default function ForgetPasswordModal() {
  const [emailAddress, setEmailAddress] = useState('');
  const [timer, setTimer] = useState(0);
  const [requestSent, setRequestSent] = useState(false);

  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const sendResetLink = async () => {
    authService.resetPassword(emailAddress).then((response) => {
      if (response?.error) toast.error(response.error);
      else {
        toast.success(
          'Your password has been reset. Please check your email and try again!'
        );
        setRequestSent(true);
        startResendTimer();
      }
    });
  };

  const startResendTimer = () => {
    setTimer(10);

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  return (
    <>
      <div className='modal auth-modal' id='myModal'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <img
              className='dots-img'
              src='../../assets/images/dots.png'
              alt='dots-img'
            />
            <img
              className='dots-img2'
              src='../../assets/images/dots.png'
              alt='dots-img'
            />
            <div className='modal-body position-relative text-center'>
              <SecuritySafe
                size='64'
                color='#7c3aed'
                variant='Bulk'
                className='mb-24'
              />
              <h4 className='text-center'>Forgot Password</h4>
              <p className='text-center mb-48 slate-500 font-normal letter-spacing'>
                Forget your password? Just enter your email address and receive
                a link to reset it right away.
              </p>

              <div className='auth-form d-flex flex-column mb-48'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Email Address'
                  value={emailAddress}
                  onChange={handleEmailChange}
                />
              </div>

              <div className='d-flex align-items-center justify-content-between '>
                <p
                  className={`violet mb-0 ${(!requestSent || timer > 0) ? 'text-muted' : ''}`}
                  style={{ cursor: (!requestSent || timer > 0) ? 'not-allowed' : 'pointer' }}
                  onClick={() => {
                    if (timer === 0) {
                      sendResetLink();
                    }
                  }}
                >
                  {timer > 0
                    ? `Resend Link - ${timer.toString().padStart(2, '0')}`
                    : 'Resend Link'}
                </p>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={sendResetLink}
                >
                  Send Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
