import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';

export default function CustomOffersQuantity({
  customPrice,
  setCustomPrice,
  createCustomOffer,
}) {
  const areAllFieldsFilled = () => {
    return customPrice > 0;
  };

  return (
    <>
      <div className='modal custom-offers-modal' id='CustomOffersQuantity'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content overflow-hidden '>
            <div className='modal-header  flex-column  slate-50 p-32 text-center'>
              <h2 className='modal-title mx-auto'>Custom Offer</h2>
              <p className='slate-500 font-normal line-height-200 mb-0'>
                Please set the custom price for this job.
              </p>
            </div>
            <div className='modal-body advance-search-modal position-relative p-32 text-center'>
              <div className='auth-form d-flex flex-column gap-32 '>
                <div className='d-flex align-items-start location-input flex-grow-1 flex-column gap-4 position-relative'>
                  <label className=''>Custom Price</label>

                  <input
                    type='text'
                    className='form-control  '
                    placeholder='Maximum Price'
                    value={customPrice}
                    onChange={(e) => setCustomPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center modal-footer confirm-modal-footer gap-3 p-32 justify-content-between '>
              <button
                type='button'
                className='btn button2'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() =>
                  areAllFieldsFilled()
                    ? createCustomOffer()
                    : toast.error('Please fill all fields')
                }
              >
                Create an Offer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
