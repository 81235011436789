import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Header from '../../layout/header';
import { Messages2, Star1 } from 'iconsax-react';
import Pagination from '../../components/Pagination';
import jobOfferService from '../../services/job-offer-service';
import { apiHost } from '../../constants';
import PaymentInformation from '../../components/PaymentInformationModal';

export default function JobOffers(props) {
  const [jobOffers, setJobOffers] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedJobOffer, setSelectedJobOffer] = useState(null);

  useEffect(() => {
    const fetchJobOffers = async () => {
      const response = await jobOfferService.getMyJobOffers();
      if (response?.error) {
        toast.error(response.error);
      } else {
        setJobOffers(response?.jobOffers);
      }
    };

    fetchJobOffers();
  }, []);

  const handleJobOfferClick = (job, offer) => {
    setSelectedJob(job);
    setSelectedJobOffer(offer);
    const modal = new window.bootstrap.Modal(
      document.getElementById('PaymentInformation')
    );
    modal.show();
  };

  const renderJobOffers = (jobOffers) => {
    return (
      <div className='col-12 job-offers-col d-flex flex-column gap-4'>
        {jobOffers.map((jobOffer) => (
          <div
            key={jobOffer.id}
            className='job-offer-card bg-white radius-16 p-4'
          >
            <div className='job-offer-header d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-3'>
                <img
                  className='rounded-circle '
                  height={'48'}
                  width={'48'}
                  src={`${apiHost}/${jobOffer?.JobImages[0]?.url}`}
                  alt='product-img'
                />
                <div className='d-flex flex-column gap-2'>
                  <p className='mb-0 line-height'>{jobOffer.title}</p>
                </div>
              </div>
            </div>
            <div className='job-offers-list mt-3'>
              <h6 className='font-size16 line-height mb-3'>Job Offers:</h6>
              {jobOffer.JobOffers.map((offer) => (
                <div
                  key={offer.id}
                  className='job-offer-item bg-light radius-12 p-3 mb-2'
                  onClick={() => handleJobOfferClick(jobOffer, offer)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center gap-3'>
                      <Messages2 size='32' color='#ef4444' variant='Bulk' />
                      <div className='d-flex flex-column gap-2'>
                        <p className='mb-0 line-height d-flex justify-content-between w-100 gap-5'>
                          <span>
                            {offer.Freelancer.firstName}{' '}
                            {offer.Freelancer.lastName}
                          </span>
                          <div className='d-flex align-items-center gap-1'>
                            <Star1 size='16' color='#facc15' variant='Bold' />
                            <span className='line-height font-size14 font-normal'>
                              {offer.Freelancer.rating}
                            </span>
                          </div>
                        </p>
                        <p className='mb-0 slate-500 font-size14 line-height font-normal'>
                          Offered Price: ${offer.offeredPrice}
                        </p>
                      </div>
                    </div>
                    <div className='d-flex align-items-center gap-3'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='8'
                        height='8'
                        viewBox='0 0 8 8'
                        fill='none'
                      >
                        <circle
                          cx='4'
                          cy='4'
                          r='3'
                          fill='#7C3AED'
                          stroke='#F5F3FF'
                          strokeWidth='2'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Header Display='none' href='' headerTitle='Job Offers' />
      <div className='container-fluid px-0'>
        <div className='row '>
          {jobOffers.length > 0 ? (
            <Pagination renderComponent={renderJobOffers} items={jobOffers} />
          ) : (
            <div className='alert alert-warning'>
              No pending job offers to display
            </div>
          )}
        </div>
      </div>
      <PaymentInformation job={selectedJob} jobOffer={selectedJobOffer} />
    </>
  );
}
