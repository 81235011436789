import { ArrowRight } from 'iconsax-react';
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import Pagination from '../../components/Pagination';
import Header from '../../layout/header';
import OrderComplete from '../../components/AcceptedJobs/OrderComplete';
import LeaveRatingReview from '../../components/AcceptedJobs/LeaveRatingReview';
import { apiHost, UserRolesEnum } from '../../constants';
import jobService from '../../services/job-service';
import JobDelivered from '../../components/AcceptedJobs/JobDelivered';
import jobOfferService from '../../services/job-offer-service';

export default function AcceptedJobs(props) {
  const location = useLocation();

  const [userRole, setUserRole] = useState('');
  const [acceptedJobs, setAcceptedJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = localStorage.getItem('user');
    const parsedUser = JSON.parse(user);
    setUserRole(parsedUser.role);
  }, []);

  const acceptJobOffer = useCallback(async (sessionId, jobOfferId) => {
    try {
      const response = await jobOfferService.acceptJobOffer(jobOfferId, {
        sessionId,
      });
      if (response?.error) {
        toast.error(response.error);
      } else {
        const url = new URL(window.location);
        url.searchParams.delete('session_id');
        url.searchParams.delete('jobOfferId');
        url.searchParams.delete('status');
        window.history.replaceState({}, document.title, url.pathname);

        window.location.reload();
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!');
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    const sessionId = queryParams.get('session_id');
    const jobOfferId = queryParams.get('jobOfferId');

    if (status === 'error') {
      toast.error('Something went wrong. Please try again!');
    }

    if (sessionId && jobOfferId) {
      acceptJobOffer(sessionId, jobOfferId);
    }
  }, [acceptJobOffer, location.search]);

  useEffect(() => {
    const fetchJobOffers = async () => {
      try {
        const response = await jobService.getMyAcceptedJobs();
        if (response?.error) {
          if (!response?.error.includes('not found'))
            toast.error(response.error);
        } else {
          setAcceptedJobs(response);
        }
      } catch (error) {
        toast.error('Failed to fetch accepted jobs');
      } finally {
        setLoading(false);
      }
    };

    fetchJobOffers();
  }, []);

  const handleJobComplete = (jobId) => {
    setAcceptedJobs((prevJobs) =>
      prevJobs.map((job) =>
        job.id === jobId ? { ...job, isCompleted: true } : job
      )
    );
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }).format(date);
  };

  const getModalTarget = (job) => {
    if (userRole === UserRolesEnum.FREELANCER) {
      if (!job.isCompleted) {
        return '';
      } else if (job.isCompleted && !job.isReviewedByFreelancer) {
        return '#OrderComplete';
      } else {
        return '';
      }
    }

    if (userRole === UserRolesEnum.RECRUITER) {
      if (!job.isCompleted) {
        return '#JobDelivered';
      } else if (job.isCompleted && !job.isReviewedByRecruiter) {
        return '#OrderComplete';
      } else {
        return '';
      }
    }

    return '';
  };

  const handleRowClick = (job) => {
    setSelectedJob(job);
    const modalTarget = getModalTarget(job);
    if (modalTarget) {
      const modal = new window.bootstrap.Modal(
        document.querySelector(modalTarget)
      );
      modal.show();
    }
  };

  const renderJobs = (jobs) => {
    return (
      <tbody>
        {loading ? (
          <tr>
            <td colSpan='4'>Loading jobs...</td>
          </tr>
        ) : jobs.length > 0 ? (
          jobs.map((job) => (
            <tr key={job.id} onClick={() => handleRowClick(job)}>
              <td>
                <div className='d-flex gap-3 align-items-center '>
                  <img
                    className='rounded-circle '
                    height={'48'}
                    width={'48'}
                    src={`${apiHost}/${job?.JobImages[0]?.url}`}
                    alt='job-img'
                  />
                  <div className='d-flex flex-column gap-2'>
                    <p className='mb-0 font-size14 line-height font-normal'>
                      {job.title}
                    </p>
                    <span className='slate-500 font-size12 d-flex align-items-center gap-2 line-height'>
                      ${job.JobOffers[0].offeredPrice}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex flex-column gap-2'>
                  <p className='mb-0 font-size14 line-height font-normal'>
                    {userRole === UserRolesEnum.RECRUITER
                      ? `${job.JobOffers[0].Freelancer.firstName} ${job.JobOffers[0].Freelancer.lastName}`
                      : `${job.Recruiter.firstName} ${job.Recruiter.lastName}`}
                  </p>
                </div>
              </td>
              <td>
                <p className='mb-0 font-size14 font-normal'>
                  {formatTimestamp(job?.JobOffers[0].acceptedTimestamp)}
                </p>
              </td>
              <td>
                <div className='d-flex align-items-center justify-content-between status-td'>
                  {job.isCompleted ? (
                    <span className='complete font-size12 line-height'>
                      Completed
                    </span>
                  ) : (
                    <span className='BeingPrepped font-size12 line-height'>
                      Accepted
                    </span>
                  )}
                  <div className='d-flex align-items-center gap-3'>
                    {(job.isCompleted &&
                      userRole === UserRolesEnum.RECRUITER &&
                      !job.isReviewedByRecruiter) ||
                    (job.isCompleted &&
                      userRole === UserRolesEnum.FREELANCER &&
                      !job.isReviewedByFreelancer) ? (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='10'
                        height='10'
                        viewBox='0 0 2 3'
                        fill='none'
                      >
                        <circle cx='1' cy='1.5' r='1' fill='#3e71d6' />
                      </svg>
                    ) : null}
                    <ArrowRight color='#38B1ED' variant='Outline' />
                  </div>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan='4'>No jobs to display</td>
          </tr>
        )}
      </tbody>
    );
  };

  return (
    <>
      <Header Display='none' href='' headerTitle='Accepted Jobs' />
      <div className='container-fluid px-0'>
        <div className='row '>
          <div className='col-12 all-orders-col'>
            <div className='all-orders bg-white radius-16 overflow-hidden'>
              <ul className='nav nav-tabs main-tabs'>
                <li className='nav-item'>
                  <a
                    className='nav-link active'
                    data-bs-toggle='tab'
                    href='#AcceptedJobs'
                  >
                    Accepted Jobs - {acceptedJobs.length}
                  </a>
                </li>
              </ul>

              <div className='tab-content'>
                <div className='tab-pane active' id='AcceptedJobs'>
                  <ul className='nav nav-tabs sub-tabs border-0 p-24'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        data-bs-toggle='tab'
                        href='#ViewAll'
                      >
                        View All
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        data-bs-toggle='tab'
                        href='#ActiveJobs'
                      >
                        Active Jobs
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        data-bs-toggle='tab'
                        href='#CompletedJobs'
                      >
                        Completed Jobs
                      </a>
                    </li>
                  </ul>

                  <div className='tab-content'>
                    <div className='tab-pane active' id='ViewAll'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th className='font-normal'>Job Detail</th>
                            <th className='font-normal'>
                              {userRole === UserRolesEnum.RECRUITER
                                ? UserRolesEnum.FREELANCER
                                : UserRolesEnum.RECRUITER}
                            </th>
                            <th className='font-normal'>Acceptance Date</th>
                            <th className='font-normal'>Status</th>
                          </tr>
                        </thead>
                        {acceptedJobs.length > 0 && (
                          <Pagination
                            items={acceptedJobs}
                            renderComponent={renderJobs}
                          />
                        )}
                      </table>
                    </div>

                    <div className='tab-pane  fade' id='ActiveJobs'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th className='font-normal'>Job Detail</th>
                            <th className='font-normal'>
                              {userRole === UserRolesEnum.RECRUITER
                                ? UserRolesEnum.FREELANCER
                                : UserRolesEnum.RECRUITER}
                            </th>
                            <th className='font-normal'>Acceptance Date</th>
                            <th className='font-normal'>Status</th>
                          </tr>
                        </thead>
                        {acceptedJobs?.filter((job) => !job.isCompleted)
                          .length > 0 && (
                          <Pagination
                            renderComponent={renderJobs}
                            items={acceptedJobs.filter(
                              (job) => !job.isCompleted
                            )}
                          />
                        )}
                      </table>
                    </div>

                    <div className='tab-pane fade' id='CompletedJobs'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th className='font-normal'>Job Detail</th>
                            <th className='font-normal'>
                              {userRole === UserRolesEnum.RECRUITER
                                ? UserRolesEnum.FREELANCER
                                : UserRolesEnum.RECRUITER}
                            </th>
                            <th className='font-normal'>Acceptance Date</th>
                            <th className='font-normal'>Status</th>
                          </tr>
                        </thead>
                        {acceptedJobs?.filter((job) => job.isCompleted).length >
                          0 && (
                          <Pagination
                            renderComponent={renderJobs}
                            items={acceptedJobs.filter(
                              (job) => job.isCompleted
                            )}
                          />
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedJob && (
        <>
          <JobDelivered
            job={selectedJob}
            onJobComplete={() => handleJobComplete(selectedJob.id)}
          />
          <OrderComplete job={selectedJob} />
          <LeaveRatingReview job={selectedJob} />
        </>
      )}
    </>
  );
}
