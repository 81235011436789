import { Lock, SecuritySafe } from "iconsax-react";
import React from "react";

export default function ResetPassword() {
  return (
    <>
      <div className="modal auth-modal" id="myModal2">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <img
              className="dots-img"
              src="../../assets/images/dots.png"
              alt="dots-img"
            />
            <img
              className="dots-img2"
              src="../../assets/images/dots.png"
              alt="dots-img"
            />
            <div className="modal-body position-relative text-center">
            <Lock
 size="64"
 color="#7c3aed"
 variant="Bulk"
 className="mb-24"
/>
              <h4 className="text-center">Reset Password</h4>
              <p className="text-center mb-48 slate-500 font-normal letter-spacing">
              Please enter a new password. It is recommended to set a password you can memorise but is strong.
              </p>

              <div className="auth-form d-flex flex-column gap-3 mb-48">
              <input
                  type="New Password"
                  className="form-control"
                  placeholder="New Password"
                />
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                />
              </div>

              <div className="d-flex align-items-center justify-content-between ">
                
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
