export const apiHost = 'https://api.workorpost.com/api';

export const UserRolesEnum = {
  RECRUITER: 'Recruiter',
  FREELANCER: 'Freelancer',
  ADMIN: 'Admin',
};

export const difficultyLevels = ['Easy', 'Medium', 'Hard'];

export const imageSizeLimit = 10;
