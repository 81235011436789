import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import userService from '../../services/user-service';
import authService from '../../services/auth-service';

export default function SignUp() {
  const navigate = useNavigate();

  useEffect(() => {
    const getAuthStatus = () => {
      const response = authService.getAuthStatus();

      if (response) navigate('/dashboard');
    };

    getAuthStatus();
  }, [navigate]);

  const [role, setRole] = useState('Recruiter');

  const { register, handleSubmit } = useForm({ mode: 'all' });

  const onFormSubmit = async (data) => {
    const payload = { ...data, role };
    delete payload['confirmPassword'];

    if (data.password === data.confirmPassword) {
      try {
        const response = await userService.registerUser(payload);
        if (response?.error) {
          toast.error(response?.error);
        } else {
          toast.success(response?.message);
          navigate('/');
        }
      } catch (error) {
        toast.error('An error occurred during sign up');
      }
    } else {
      toast.error('Password and confirm password do not match');
    }
  };

  return (
    <>
      <div className='container-fluid auth-container'>
        <div className='row'>
          <div className='col-12 col-md-6 sign-img-col'>
            <img
              className='w-100 auth-img'
              style={{ minHeight: '100vh', height: 'unset' }}
              src='../../assets/images/auth-img.jpg'
              alt='login-img'
            />
          </div>

          <div className='col-12 col-md-6 auth-col d-flex flex-column justify-content-between p-32'>
            <div className='w-100'>
              <div className='d-flex align-content-center justify-content-between mb-64'>
                <img
                  className='img-fluid'
                  style={{ width: '100px' }}
                  src='../../assets/images/logo.png'
                  alt='login-logo'
                />
                <Link to={'/'} className='create-btn'>
                  Sign in
                </Link>
              </div>

              <div className='auth-desc d-flex flex-column gap-3 mb-64'>
                <h2 className='font-normal mb-0'>Create an Account</h2>
                <p className='font-normal slate-500 mb-0'>
                  New to the platform? Just fill in the details mentioned below
                  to get started with your very own account.
                </p>
              </div>

              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className='auth-form d-flex flex-column gap-24 mb-4'>
                  <div className='m-2'>
                    <label className='form-label mb-3'>Role</label>
                    <div className='d-flex'>
                      <div className='form-check me-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Recruiter'
                          checked={role === 'Recruiter'}
                          onChange={() => setRole('Recruiter')}
                          id='recruiter'
                        />
                        <label
                          className='form-check-label font-normal'
                          htmlFor='recruiter'
                        >
                          Job Poster
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Freelancer'
                          checked={role === 'Freelancer'}
                          onChange={() => setRole('Freelancer')}
                          id='freelancer'
                        />
                        <label
                          className='form-check-label font-normal'
                          htmlFor='freelancer'
                        >
                          Freelancer
                        </label>
                      </div>
                    </div>
                  </div>
                  <input
                    type='text'
                    id='firstName'
                    className='form-control'
                    placeholder='First Name'
                    required
                    {...register('firstName')}
                  />
                  <input
                    type='text'
                    id='lastName'
                    className='form-control'
                    placeholder='Last Name'
                    required
                    {...register('lastName')}
                  />
                  <input
                    type='email'
                    id='email'
                    className='form-control'
                    placeholder='Email Address'
                    required
                    {...register('email')}
                  />
                  <input
                    type='password'
                    id='password'
                    className='form-control'
                    placeholder='Password'
                    required
                    {...register('password')}
                  />
                  <input
                    type='password'
                    id='confirmPassword'
                    className='form-control'
                    placeholder='Confirm Password'
                    required
                    {...register('confirmPassword')}
                  />
                </div>

                <div className='d-flex align-items-center justify-content-between'>
                  <button type='submit' className='btn'>
                    Get Started
                  </button>
                  <Link to={'/dashboard'} className='create-btn'>
                    Continue As Guest
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
